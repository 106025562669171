import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getAllCountries } from 'apiRequests';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';

const CountriesSelection = ({ setSelectedCountry, selectedCountry }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [newCountry, setNewCountry] = useState({
    code: '',
    name: {
      en: '',
      ru: '',
      he: ''
    }
  });
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const { data } = await getAllCountries();

      if (active) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  const handleChange = e => {
    const {
      target: { name, value }
    } = e;
    setNewCountry({ ...newCountry, name: { ...newCountry.name, [name]: value } });
  };

  const handleNewCountryAdded = () => {
    if (!newCountry.code || !newCountry.name.en || !newCountry.name.ru || !newCountry.name.he) {
      alert('One of the fields is empty. Check the fields');
    } else {
      setSelectedCountry(newCountry);
      setDialogOpened(false);
    }
  };
  return (
    <>
      <Autocomplete
        value={selectedCountry || null}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(e, newValue) => {
          setSelectedCountry(newValue || []);
        }}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={option => option.name && option.name.en}
        options={options}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            // value={selectedCountry||null}
            label='Countries'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  <IconButton style={{ height: 24, width: 24 }}>
                    <AddIcon onClick={() => setDialogOpened(true)} />
                  </IconButton>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      <Dialog open={dialogOpened} onClose={() => setDialogOpened(false)}>
        <DialogTitle>Add new country</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 8 }}>Name</div>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <TextField
                name='en'
                onChange={handleChange}
                value={newCountry && newCountry.name && newCountry.name.en}
                variant='outlined'
                label='English'
              ></TextField>
            </Grid>
            <Grid item md={4}>
              <TextField
                onChange={handleChange}
                value={newCountry.name && newCountry.name.ru}
                name='ru'
                variant='outlined'
                label='Russian'
              ></TextField>
            </Grid>
            <Grid item md={4}>
              <TextField
                name='he'
                value={newCountry.name.he}
                onChange={handleChange}
                variant='outlined'
                label='Hebrew'
              ></TextField>
            </Grid>
          </Grid>
          <div style={{ marginTop: 8, marginBottom: 8 }}>Code</div>
          <TextField
            variant='outlined'
            label='Code'
            style={{ width: 64 }}
            onChange={({ target: { value } }) => setNewCountry({ ...newCountry, code: value.toLowerCase() })}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleNewCountryAdded}>
            OK
          </Button>
          <Button variant='outlined' onClick={() => setDialogOpened(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CountriesSelection;
