export const stationsComparator = (s1, s2) => {
  if (s1.LocationType === 'airport' && s2.LocationType !== 'airport') {
    return -1;
  }
  if (s1.importance === undefined && s2.importance !== undefined) {
    return 1;
  }
  if (s2.importance === undefined && s1.importance !== undefined) {
    return -1;
  }
  return s1.importance - s2.importance;
};

export const getExtensions = () => {
  return [
    { columnName: 'LocationCode', width: 70 },
    { columnName: 'iataCodeId', width: 80 },
    { columnName: 'LocationType', width: 90 },
    { columnName: 'locationName_en', width: 200 },
    { columnName: 'locationName_ru', width: 200 },
    { columnName: 'locationName_he', width: 200 },
    { columnName: 'name_en', width: 200 },
    { columnName: 'name_ru', width: 200 },
    { columnName: 'name_he', width: 200 },
    { columnName: 'city_en', width: 200 },
    { columnName: 'city_ru', width: 200 },
    { columnName: 'city_he', width: 200 },
    { columnName: 'ofranCountryId', width: 70, align: 'center' },
    { columnName: 'addcarCountryId', width: 80, align: 'center' }
  ];
};
export const getColumns = () => {
  return [
    { name: 'LocationCode', title: 'Code', required: true },
    { name: 'iataCodeId', title: 'Iata Code', required: true },
    { name: 'LocationType', title: 'Type', required: true },
    {
      name: 'locationName_en',
      title: 'EN',
      getCellValue: row => (row.LocationName ? row.LocationName.en : '')
    },
    {
      name: 'locationName_ru',
      title: 'RU',
      getCellValue: row => (row.LocationName ? row.LocationName.ru : '')
    },
    {
      name: 'locationName_he',
      title: 'HE',
      getCellValue: row => (row.LocationName ? row.LocationName.he : '')
    },
    { name: 'name_en', title: 'EN', getCellValue: row => (row.name ? row.name.en : '') },
    { name: 'name_ru', title: 'RU', getCellValue: row => (row.name ? row.name.ru : '') },
    { name: 'name_he', title: 'HE', getCellValue: row => (row.name ? row.name.he : '') },
    { name: 'city_en', title: 'EN', getCellValue: row => (row.city ? row.city.en : '') },
    { name: 'city_ru', title: 'RU', getCellValue: row => (row.city ? row.city.ru : '') },
    { name: 'city_he', title: 'HE', getCellValue: row => (row.city ? row.city.he : '') },
    {
      name: 'ofranCountryId',
      title: 'Ofran ID',
      getCellValue: row => {
        const idx = row.apis ? row.apis.findIndex(item => item.apiType === 3) : -1;
        if (idx > -1) {
          return row.apis[idx].countryID;
        } else return '';
      }
    },
    {
      name: 'addcarCountryId',
      title: 'addCar ID',
      getCellValue: row => {
        const idx = row.apis ? row.apis.findIndex(item => item.apiType === 2) : -1;
        if (idx > -1) {
          return row.apis[idx].countryID;
        } else return '';
      }
    }
  ];
};

export const getColumnsBands = () => {
  return [
    {
      title: 'Location Name',
      children: [
        { columnName: 'locationName_en' },
        { columnName: 'locationName_ru' },
        { columnName: 'locationName_he' }
      ]
    },
    {
      title: 'City',
      children: [{ columnName: 'city_en' }, { columnName: 'city_ru' }, { columnName: 'city_he' }]
    },
    {
      title: 'Name',
      children: [{ columnName: 'name_en' }, { columnName: 'name_ru' }, { columnName: 'name_he' }]
    }
  ];
};

const getCustomValue = (lng, columnName, modelColumnName) => {
  return {
    columnName: `${columnName}_${lng}`,
    createRowChange: (row, value) => {
      return { ...row, [modelColumnName]: { ...row[modelColumnName], [lng]: value } };
    }
  };
};

const getCountryColumn = (columnName, apiType) => {
  return [
    {
      columnName,
      createRowChange: (row, value) => {
        if (!row.apis) {
          return { ...row, apis: [{ apiType, countryID: !isNaN(value) ? Number(value) : '' }] };
        } else {
          const idx = row.apis.findIndex(api => api.apiType === apiType);
          if (idx > -1) {
            row.apis[idx].countryID = !isNaN(value) ? Number(value) : '';
            return row;
          } else {
            row.apis = [...row.apis, { apiType, countryID: !isNaN(value) ? Number(value) : '' }];
            return row;
          }
        }
      }
    }
  ];
};

export const getEditingColumnExtensions = () => {
  const languages = ['en', 'ru', 'he'];
  const columnNames = ['locationName', 'name', 'city'];
  const modelColumnName = ['LocationName', 'name', 'city'];

  const customLocations = languages.map(lng => {
    return getCustomValue(lng, columnNames[0], modelColumnName[0]);
  });
  const customNames = languages.map(lng => {
    return getCustomValue(lng, columnNames[1], modelColumnName[1]);
  });

  const customModelColumnNames = languages.map(lng => {
    return getCustomValue(lng, columnNames[2], modelColumnName[2]);
  });

  const customColumns = [...customLocations, ...customNames, ...customModelColumnNames];
  const ofranCountryColumn = getCountryColumn('ofranCountryId', 3);
  const addCarCountryColumn = getCountryColumn('addcarCountryId', 2);
  return [...customColumns, ...ofranCountryColumn, ...addCarCountryColumn];
};
