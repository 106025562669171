import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';
const Popup = ({ row, onChange, onApplyChanges, open, onCancelChanges }) => {
  const { prices } = row;
  const protection_coverage = prices ? prices.protection_coverage : [];
  const topProtection = protection_coverage.find(p => p.code === 'FC');
  console.log('ROW', row);
  const tw = protection_coverage.find(p => p.code === 'TW');
  const getTextFieldProperties = (name, label, value) => ({
    name,
    label,
    onChange,
    fullWidth: true,
    variant: 'outlined',
    value
  });

  return (
    <Dialog open={open} onClose={onCancelChanges}>
      <DialogTitle id='alert-dialog-title'>
        <Typography component='span' variant='h4'>
          {row && row.supplier && row.supplier.name ? 'Edit car' : 'Add car'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                {...getTextFieldProperties('modelName', 'Model', row && row.modelName ? row.modelName : '')}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...getTextFieldProperties('sippCode', 'Sipp code', row && row.sippCode ? row.sippCode : '')}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField {...getTextFieldProperties('year', 'Year', row && row.year ? row.year : '')} />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 16 }}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16, marginBottom: 8 }}>
              <Typography component='span' variant='h5'>
                {' '}
                Protection prices per day
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...getTextFieldProperties('FC', 'Top protection', topProtection ? topProtection.price : 0)}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>€</InputAdornment>
                }}
                label='Top protection'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position='start'>€</InputAdornment>
                }}
                {...getTextFieldProperties('TW', 'Tyres windows protection', tw ? tw.price : 0)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 8 }}>
              <Typography variant='h5'> Protection prices per month</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                {...getTextFieldProperties('FC', 'Top protection', topProtection ? topProtection.monthly : 0)}
                value={
                  topProtection
                    ? topProtection.monthly === -1
                      ? 'Unavailable'
                      : topProtection
                      ? topProtection.monthly
                      : 0
                    : 0
                }
                InputProps={{
                  startAdornment: <InputAdornment position='start'>€</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position='start'>€</InputAdornment>
                }}
                fullWidth
                disabled
                variant='outlined'
                value={tw ? (tw.monthly === -1 ? 'Unavailable' : tw.price.monthly) : 0}
                label='Tyres windows protection'
              />
            </Grid>
            <Grid xs={12} style={{ marginTop: 16 }}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <Typography variant='h5'>Other</Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel id='demo-simple-select-label'>Model Guaranteeed</InputLabel>
                <Select onChange={onChange} value={row.isModelGuaranteed} label='Model Guaranteeed'>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} style={{ marginTop: 16 }}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <Typography variant='h5'>Image</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField {...getTextFieldProperties('image', 'Image', row && row.image ? row.image : '')} />
            </Grid>
            <Grid item>
              <img src={row.image} alt={row.modelName} />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelChanges} variant='outlined'>
          Cancel
        </Button>
        <Button onClick={onApplyChanges} variant='outlined'>
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
