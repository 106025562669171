import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { addNewHoliday } from 'apiRequests';
import React, { useEffect, useState } from 'react';

const holidays = [
  {
    holidayName: {
      en: 'Passover',
      ru: 'Песах',
      he: 'פסח'
    },
    holidayType: 'holiday'
  },
  {
    holidayName: {
      en: 'Passover 7th Day',
      ru: 'Песах 7 день',
      he: 'פסח יום שביעי'
    },
    holidayType: 'holiday'
  },
  {
    holidayName: {
      en: 'Independence Day',
      ru: 'День независимости Израиля',
      he: 'יום העצמאות'
    },
    holidayType: 'holiday'
  },
  {
    holidayName: {
      en: 'Shavuot',
      ru: 'Шавуот',
      he: 'שבועות'
    },
    holidayType: 'holiday'
  },
  {
    holidayName: {
      en: 'Rosh Hashanah',
      ru: 'Рош ха-Шана',
      he: 'ראש השנה'
    },
    holidayType: 'holiday'
  },
  {
    holidayName: {
      en: 'Yom Kippur',
      ru: 'Йом-кипур',
      he: 'יום כיפור'
    },
    holidayType: 'kippur'
  },
  {
    holidayName: {
      en: 'Sukkot 1 day',
      ru: 'Суккот 1 день',
      he: 'סוכות יום א'
    },
    holidayType: 'holiday'
  },
  {
    holidayName: {
      en: 'Shemini Atezeret',
      ru: 'Шмени Ацерет',
      he: 'שמיני עצרת'
    },
    holidayType: 'holiday'
  },
  {
    holidayName: {
      en: 'Simhat Tora',
      ru: 'Симхат Тора',
      he: 'שמחת תורה'
    },
    holidayType: 'holiday'
  }
];
const AddNewHolidayDialog = ({ open, handleOpenDialog, setFetchTrigger }) => {
  const [holiday, setHoliday] = useState({
    country: { code: 'il', name: 'Israel' },
    supplier: { name: 'Europcar', code: 'europcar' },
    type: 'holiday',
    from: new Date(),
    until: new Date()
  });

  const [selectedHoliday, setSelectedHoliday] = useState({});

  // useEffect(() => {
  //   console.log('HOLIDAY is', holiday);
  // }, [holiday]);

  const handleAddNewHoliday = () => {
    addNewHoliday({ ...holiday, ...selectedHoliday })
      .then(res => {
        alert(res.data.message);
      })
      .catch(err => {
        console.log(err);
        alert(err.response.data.message);
      })
      .finally(() => {
        handleOpenDialog('addNewHoliday', false);
        setFetchTrigger(prevState => ({
          fetchTrigger: !prevState.changes
        }));
      });
  };
  const handleChange = ({ target: { value } }) => {
    setSelectedHoliday(value);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle style={{ textAlign: 'center' }}>Choose dates</DialogTitle>
      <DialogContent style={{ paddingBottom: 16 }}>
        <Container style={{ textAlign: 'center' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={1} direction='row'>
              <Grid item xs={12}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel>Select a holiday</InputLabel>

                  <Select value={selectedHoliday} onChange={handleChange} label={'Select a holiday'}>
                    {holidays.map((hol, idx) => {
                      return (
                        <MenuItem value={hol} key={idx}>
                          {hol.holidayName.en}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  fullWidth
                  style={{ marginRight: 16 }}
                  label='From'
                  value={holiday.from}
                  onChange={e => setHoliday({ ...holiday, from: e })}
                  animateYearScrolling
                  inputVariant='outlined'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  fullWidth
                  label='Until'
                  value={holiday.until}
                  onChange={e => setHoliday({ ...holiday, until: e })}
                  animateYearScrolling
                  inputVariant='outlined'
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpenDialog('addNewHoliday', false)}>Cancel</Button>
        <Button onClick={handleAddNewHoliday}>SAVE</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewHolidayDialog;
