import React, { useState } from 'react';
import { EditingState, SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableBandHeader,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  VirtualTable,
  TableFixedColumns,
  Table
} from '@devexpress/dx-react-grid-material-ui';

import Paper from '@material-ui/core/Paper';
import { useEffect } from 'react';
import { changeCar } from '../../../../apiRequests';
import AlertMessage from '../AlertMessage';
import { changeInsurances, getExtenstions } from './utilities';

const CarsPriceListTable = ({ carsPrices, priceListID, setChanges }) => {
  const [isSaved, setIsSaved] = useState({ status: false, type: 'success', message: '' });
  const [rows, setRows] = useState(
    carsPrices.map((row, id) => {
      return { ...row, id };
    })
  );
  const getRowId = row => row.id;

  useEffect(() => {
    setRows(
      carsPrices.map((row, id) => {
        return { ...row, id };
      })
    );
  }, [carsPrices]);
  // useEffect(() => {
  //   console.log('rows', rows);
  // }, [rows]);
  const [columns] = useState([
    { name: 'vehicle_type', title: 'Category' },
    { name: 'sipp_code', title: 'SIPP' },
    {
      name: 'daily1to2daysLow',
      title: 'Low',
      getCellValue: row => (row.prices ? row.prices.periods.daily1to2days.low : '')
    },
    {
      name: 'daily1to2daysHigh',
      title: 'High',
      getCellValue: row => (row.prices ? row.prices.periods.daily1to2days.high : '')
    },
    {
      name: 'daily3to6daysLow',
      title: 'Low',
      getCellValue: row => (row.prices ? row.prices.periods.daily3to6days.low : '')
    },
    {
      name: 'daily3to6daysHigh',
      title: 'High',
      getCellValue: row => (row.prices ? row.prices.periods.daily3to6days.high : '')
    },
    {
      name: 'weeklyLow',
      title: 'Low',
      getCellValue: row => (row.prices ? row.prices.periods.weekly_unlimited.low : '')
    },
    {
      name: 'weeklyHigh',
      title: 'High',
      getCellValue: row => (row.prices ? row.prices.periods.weekly_unlimited.high : '')
    },
    {
      name: 'monthlyLow',
      title: 'Low',
      getCellValue: row => (row.prices ? row.prices.periods.monthly.low : '')
    },
    {
      name: 'monthlyHigh',
      title: 'High',
      getCellValue: row => (row.prices ? row.prices.periods.monthly.high : '')
    },
    {
      name: 'CDW',
      title: 'CDW',
      getCellValue: row =>
        row.prices && row.prices.protection_coverage[0].price ? row.prices.protection_coverage[0].price : ''
    },
    {
      name: 'TP',
      title: 'THW',
      getCellValue: row => {
        // console.log(row);
        return row.prices && row.prices.protection_coverage[1].price
          ? row.prices.protection_coverage[1].price
          : '';
      }
    },

    {
      name: 'LIA',
      title: 'LIA',
      getCellValue: row =>
        row.prices && row.prices.protection_coverage[2] && row.prices.protection_coverage[2].price
          ? row.prices.protection_coverage[2].price
          : ''
    },
    {
      name: 'SCDW',
      title: 'Daily',
      getCellValue: row =>
        row.prices &&
        row.prices.protection_coverage[3] &&
        row.prices.protection_coverage[3] &&
        row.prices.protection_coverage[3].price
          ? row.prices.protection_coverage[3].price
          : ''
    },
    {
      name: 'monthlySCDW',
      title: 'Monthly',
      getCellValue: row =>
        row.prices && row.prices.protection_coverage[3].monthly
          ? row.prices.protection_coverage[3].monthly
          : ''
    },
    {
      name: 'STP',
      title: 'Daily',
      getCellValue: row =>
        row.prices && row.prices.protection_coverage[4].price ? row.prices.protection_coverage[4].price : ''
    },
    {
      name: 'monthlySTP',
      title: 'Monthly',
      getCellValue: row =>
        row.prices && row.prices.protection_coverage[4].monthly
          ? row.prices.protection_coverage[4].monthly
          : ''
    }
  ]);
  const [columnBands] = useState([
    {
      title: 'Periods',
      children: [
        {
          title: '1-2 days',
          children: [{ columnName: 'daily1to2daysLow' }, { columnName: 'daily1to2daysHigh' }]
        },
        {
          title: '3-6 days',
          children: [{ columnName: 'daily3to6daysLow' }, { columnName: 'daily3to6daysHigh' }]
        },
        {
          title: 'weekly',
          children: [{ columnName: 'weeklyLow' }, { columnName: 'weeklyHigh' }]
        },
        {
          title: 'monthly',
          children: [{ columnName: 'monthlyLow' }, { columnName: 'monthlyHigh' }]
        }
      ]
    },
    {
      title: 'Insurances',
      children: [
        { title: 'CSW', columnName: 'CDW' },
        { title: 'TP', columnName: 'TP' },
        { title: 'LIA', columnName: 'LIA' },
        { title: 'SCDW', children: [{ columnName: 'SCDW' }, { columnName: 'monthlySCDW' }] },
        { title: 'STHW', children: [{ columnName: 'STP' }, { columnName: 'monthlySTP' }] }
      ]
    }
  ]);

  const [tableColumnExtensions] = useState(getExtenstions);

  const BandCell = ({ tableColumn, style, ...restProps }) => (
    <TableBandHeader.Cell
      style={{
        ...style,
        border: '1px solid #d9c8c8',
        textAlign: 'center'
      }}
      {...restProps}
    />
  );
  const [editingColumnExtensions] = useState([
    {
      columnName: 'daily1to2daysLow',
      createRowChange: (row, value) => ({
        prices: {
          ...row.prices,
          periods: {
            ...row.prices.periods,
            daily1to2days: { ...row.prices.periods.daily1to2days, low: value }
          }
        }
      })
    },
    {
      columnName: 'daily1to2daysHigh',
      createRowChange: (row, value) => ({
        prices: {
          ...row.prices,
          periods: {
            ...row.prices.periods,
            daily1to2days: { ...row.prices.periods.daily1to2days, high: value }
          }
        }
      })
    },
    {
      columnName: 'daily3to6daysLow',
      createRowChange: (row, value) => ({
        prices: {
          ...row.prices,
          periods: {
            ...row.prices.periods,
            daily3to6days: { ...row.prices.periods.daily3to6days, low: value }
          }
        }
      })
    },
    {
      columnName: 'daily3to6daysHigh',
      createRowChange: (row, value) => ({
        prices: {
          ...row.prices,
          periods: {
            ...row.prices.periods,
            daily3to6days: { ...row.prices.periods.daily3to6days, high: value }
          }
        }
      })
    },
    {
      columnName: 'weeklyLow',
      createRowChange: (row, value) => ({
        prices: {
          ...row.prices,
          periods: {
            ...row.prices.periods,
            weekly_unlimited: { ...row.prices.periods.weekly_unlimited, low: value }
          }
        }
      })
    },
    {
      columnName: 'weeklyHigh',
      createRowChange: (row, value) => ({
        prices: {
          ...row.prices,
          periods: {
            ...row.prices.periods,
            weekly_unlimited: { ...row.prices.periods.weekly_unlimited, high: value }
          }
        }
      })
    },
    {
      columnName: 'monthlyLow',
      createRowChange: (row, value) => ({
        prices: {
          ...row.prices,
          periods: {
            ...row.prices.periods,
            monthly: { ...row.prices.periods.monthly, low: value }
          }
        }
      })
    },
    {
      columnName: 'monthlyHigh',
      createRowChange: (row, value) => ({
        prices: {
          ...row.prices,
          periods: {
            ...row.prices.periods,
            monthly: { ...row.prices.periods.monthly, high: value }
          }
        }
      })
    },
    {
      columnName: 'CDW',
      createRowChange: (row, value) => changeInsurances(row, value, 'CDW', false)
    },
    {
      columnName: 'TP',
      createRowChange: (row, value) => changeInsurances(row, value, 'TP', false)
    },
    {
      columnName: 'monthlySCDW',
      createRowChange: (row, value) => changeInsurances(row, value, 'SCDW', true)
    },
    {
      columnName: 'monthlySTP',
      createRowChange: (row, value) => changeInsurances(row, value, 'STP', true)
    },
    {
      columnName: 'LIA',
      createRowChange: (row, value) => changeInsurances(row, value, 'LIA', false)
    },
    {
      columnName: 'SCDW',
      createRowChange: (row, value) => changeInsurances(row, value, 'SCDW', false)
    },
    {
      columnName: 'STP',
      createRowChange: (row, value) => changeInsurances(row, value, 'STP', false)
    }
  ]);

  const saveChanges = async carToChange => {
    // console.log('carToChange', carToChange);
    return changeCar(priceListID, carToChange);
  };
  // const saveRowChanged = (_id, parameters) => {
  //   console.log('_id', _id, 'parameters', parameters);
  //   changeCarPrice(_id, parameters)
  //     .then(res => console.log('saved'))
  //     .catch(err => console.error(err));
  // };
  const commitChanges = async ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      // const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      // changedRows = [
      //   ...rows,
      //   ...added.map((row, index) => ({
      //     id: startingAddedId + index,
      //     ...row
      //   }))
      // ];
      console.log('ADDING');
    }
    if (changed) {
      const rowID = Object.keys(changed)[0];
      try {
        await saveChanges({ ...rows[rowID], ...changed[rowID] });
        setIsSaved({ status: true, type: 'success', message: 'changes saved!' });
        // setTimeout(() => {
        //   setChanges(prevState => ({ changes: !prevState.changes }));
        // }, 1000);
      } catch (err) {
        setIsSaved({ status: true, type: 'error', message: "can't save the changes" });
        console.log(err.response);
      }

      changedRows = rows.map(row => {
        if (changed[row.id]) {
          console.log(row);
          return { ...row, ...changed[row.id] };
        }
        //
        else return row;
      });
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      console.log(deletedSet);
      changedRows = rows.filter(row => {
        return !deletedSet.has(row.id);
      });
    }
    /**TODO delete the row  from db*/
    setRows(changedRows);
  };
  const compareCDW = (a, b) => {
    console.log('a, b', a, b);
    return b - a;
  };

  const [sorting, setSorting] = useState([{ columnName: 'daily1to2daysHigh', direction: 'asc' }]);

  // useEffect(() => {
  //   console.log('SORTING', sorting);
  // }, [sorting]);

  const [leftColumns] = useState([TableEditColumn.COLUMN_TYPE, 'vehicle_type', 'sipp_code']);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: sorting.columnName, compare: compareCDW }
  ]);

  return (
    <Paper style={{ position: 'relative' }}>
      <AlertMessage isSaved={isSaved} setIsSaved={setIsSaved} />

      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <EditingState onCommitChanges={commitChanges} columnExtensions={editingColumnExtensions} />
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          // defaultSorting={[{ columnName: 'daily1to2daysHigh', direction: 'asc' }]}
        />
        <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />

        <Table columnExtensions={tableColumnExtensions} />
        <TableEditRow />

        <TableHeaderRow showSortingControls cellComponent={BandCell} />

        <TableEditColumn showEditCommand showDeleteCommand />
        <TableBandHeader columnBands={columnBands} cellComponent={BandCell} />
        <TableFixedColumns leftColumns={leftColumns} />
      </Grid>
    </Paper>
  );
};

export default CarsPriceListTable;
