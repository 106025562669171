import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${
      index % 2 === 0 ? '00' : '30'
    }`
);
const currencies = ['USD', 'EUR', 'ILS', 'CHF', 'GBP', 'HUF', 'AED'];
const LocationAndTime = ({ order, setOrder, setCountries, countries }) => {
  // useEffect(() => {
  //   console.log(countries);
  // }, [countries]);
  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: value });
  };
  return (
    <>
      <Typography style={{ textAlign: 'center' }} variant="h6">
        Location and time
      </Typography>
      <Grid container spacing={1} style={{ maxWidth: '800px' }}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Country</InputLabel>
            <Select
              fullWidth
              value={order.country}
              onChange={(e) => setOrder({ ...order, country: e.target.value })}
            >
              {countries.map((country) => (
                <MenuItem value={country}>{country.name.en}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Currency</InputLabel>
            <Select
              fullWidth
              value={order.currencyCode}
              onChange={(e) =>
                setOrder({ ...order, currencyCode: e.target.value })
              }
            >
              {currencies.map((currency) => {
                return <MenuItem value={currency}>{currency}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={7}>
          <TextField
            fullWidth
            label="Pick Up Location"
            name="pickUpLocation"
            value={order.pickUpLocation.LocationName}
            onChange={(e) =>
              setOrder({
                ...order,
                pickUpLocation: {
                  ...order.pickUpLocation,
                  LocationName: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Phone"
            name="pickUpLocationPhone"
            value={order.pickUpLocation.phone}
            onChange={(e) =>
              setOrder({
                ...order,
                pickUpLocation: {
                  ...order.pickUpLocation,
                  phone: e.target.value,
                },
              })
            }
          />
        </Grid>
        <TextField
          fullWidth
          label="Address"
          value={order.pickUpLocation.Address}
          onChange={(e) =>
            setOrder({
              ...order,
              pickUpLocation: {
                ...order.pickUpLocation,
                Address: e.target.value,
              },
            })
          }
        />

        <Grid item xs={12} sm={6} lg={4}>
          <DatePicker
            variant="inline"
            disableToolbar
            autoOk
            value={order.pickUpDate}
            format="dd/MM/yyyy"
            label="Pick Up Date"
            onChange={(date) =>
              setOrder({ ...order, pickUpDate: new Date(date).toISOString() })
            }
            disablePast
          />
        </Grid>
        <Grid item sm={6} lg={4}>
          <FormControl fullWidth>
            <InputLabel>Pick up time</InputLabel>
            <Select
              value={order.pickUpTime}
              items={timeSlots}
              name="pickUpTime"
              label={'Pick Up Time'}
              type="time"
              onChange={handleTimeChange}
            >
              {timeSlots.map((slot) => {
                return <MenuItem value={slot}>{slot}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={7}>
          <TextField
            fullWidth
            label="Drop Off Location"
            type="location"
            value={order.dropOffLocation.LocationName}
            onChange={(e) =>
              setOrder({
                ...order,
                dropOffLocation: {
                  ...order.dropOffLocation,
                  LocationName: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Phone"
            name="dropOffLocationPhone"
            value={order.dropOffLocation.phone}
            onChange={(e) =>
              setOrder({
                ...order,
                dropOffLocation: {
                  ...order.dropOffLocation,
                  phone: e.target.value,
                },
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address"
          value={order.dropOffLocation.Address}
          onChange={(e) =>
            setOrder({
              ...order,
              dropOffLocation: {
                ...order.dropOffLocation,
                Address: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid
        container
        justify="space-between"
        spacing={1}
        style={{ maxWidth: '800px' }}
      >
        <Grid item xs={12} sm={4} lg={4}>
          <DatePicker
            variant="inline"
            disableToolbar
            autoOk
            value={order.dropOffDate}
            format="dd/MM/yyyy"
            label={'Drop Off Date'}
            onChange={(date) =>
              setOrder({ ...order, dropOffDate: new Date(date).toISOString() })
            }
            disablePast
          />
        </Grid>
        <Grid item sm={4} lg={4}>
          <FormControl fullWidth>
            <InputLabel>Drop off time</InputLabel>
            <Select
              value={order.dropOffTime}
              items={timeSlots}
              label="Drop off Time"
              type="time"
              name="dropOffTime"
              onChange={handleTimeChange}
            >
              {timeSlots.map((slot) => {
                return <MenuItem value={slot}>{slot}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <TextField
            value={order.numberOfDays}
            required
            label="Number of days"
            type="number"
            onChange={(e) =>
              setOrder({ ...order, numberOfDays: parseInt(e.target.value) })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LocationAndTime;
