import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import React from 'react';

const DeletePriceListDialog = ({ open, setDialogOpen, handleDeletePriceList }) => {
  return (
    <Dialog open={open} onClose={() => setDialogOpen({ dialogName: 'DeletePriceList', open: false })}>
      <DialogTitle>Do you really want to delete this price list?</DialogTitle>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant='outlined'
          autoFocus={true}
          onClick={() => setDialogOpen({ dialogName: 'DeletePriceList', open: false })}
        >
          no
        </Button>
        <Button variant='outlined' color='error' onClick={() => handleDeletePriceList()}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePriceListDialog;
