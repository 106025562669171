export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE_ROWS':
      return {
        ...state,
        ...payload,
        loading: false
      };
    case 'START_LOADING':
      return {
        ...state,
        requestedSkip: payload.requestedSkip,
        take: payload.take
      };
    case 'REQUEST_ERROR':
      return {
        ...state,
        loading: false
      };
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true
      };
    case 'UPDATE_QUERY':
      return {
        ...state,
        lastQuery: payload
      };
    default:
      return state;
  }
};
