import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import React, { useState } from 'react';
import { setStopSalesDates } from 'apiRequests';

const StopSaleDialog = ({ open, setDialogOpen }) => {
  const [from, setFrom] = useState(new Date());
  const [until, setUntil] = useState(new Date());
  const handleStopSaleDates = () => {
    setStopSalesDates('il', { name: 'Europcar', code: 'europcar' }, from, until)
      .then(res => {
        alert(res.data.message);
      })
      .catch()
      .finally(setDialogOpen({ dialogName: '', isOpen: false }));
  };
  //   React.useEffect(() => {
  //     console.log('FROM', from);
  //   }, [from]);
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle style={{ textAlign: 'center' }}>Choose dates</DialogTitle>
      <DialogContent style={{ paddingBottom: 16 }}>
        <Container style={{ textAlign: 'center' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              style={{ marginRight: 16 }}
              label='From'
              value={from}
              onChange={setFrom}
              animateYearScrolling
              inputVariant='outlined'
            />
            <DatePicker
              label='Until'
              value={until}
              onChange={setUntil}
              animateYearScrolling
              inputVariant='outlined'
            />
          </MuiPickersUtilsProvider>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen({ dialogName: '', isOpen: false })}>CLOSE</Button>
        <Button onClick={handleStopSaleDates}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopSaleDialog;
