// export const makePlainOrder = order => {
//   let plainOrder = {};
//   for (let [key, value] of Object.entries(order)) {
//     if (typeof value !== 'object') {
//       plainOrder[key] = order[key];
//     } else {
//       if (value.firstName) {
//         value.name = value.firstName + ' ' + value.lastName;
//       }
//       plainOrder = { ...plainOrder, ...value };
//     }
//   }
//   return plainOrder;
// };

export const roundUpToTwoDigits = number => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const getNumberOfDays = (pickUpDate, dropOffDate) => {
  const diffTime = Math.abs(dropOffDate - pickUpDate);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const onSave = (workbook, name) => {
  console.log('workbook', workbook);
  workbook.xlsx.writeBuffer().then(buffer => {
    require('file-saver').saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${name}.xlsx`);
  });
};

export const isSameDate = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};
/**
 *
 * @param {[]} leads
 * @returns {[[], Number]} array of leads and number of new leads
 */
export const makeRowsFromLeads = customers => {
  const leadsArray = [];
  let todayLeads = 0;
  customers.forEach((customer, index) => {
    customer.lead.forEach((item, idx) => {
      if (isSameDate(new Date(item.creationDate), new Date())) todayLeads++;
      const fullName = customer.firstName + ' ' + customer.lastName;
      let tempLead = { ...customer, lead: item, fullName };
      leadsArray.push(tempLead);
    });
  });
  return [leadsArray, todayLeads];
};
