import React, { useEffect } from 'react';
import { useState } from 'react';
import { Grid, Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { ViewProgressBar, ViewContainer, AddAndSaveButton } from 'assets/reusableItems/items';
import { isSameDate } from 'helpers/utils';

import LeadsTable from './LeadsTable';
import AddNewLead from './Components/AddNewLead';
import AddQuotation from './Components/AddQuotation/AddQuotation';
import LeadDescription from './LeadDescription';
import AddQuotationDialog from 'views/Quotes/Components/AddQuotationDialog';
import { getAllLeads } from 'apiRequests';

const Leads = ({ numberOfNewLeads, location: { state } }) => {
  const [changes, setChanges] = useState(false);
  const [createdLead, setCreatedLead] = useState();
  const [leads, setLeads] = useState([]);
  const [currentLead, setCurrentLead] = useState();

  const handleChanges = () => {
    setChanges(prevState => ({ changes: !prevState.changes }));
  };
  // const url = orderURL(process.env.NODE_ENV, 'getallcustomers');

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState({
    addLead: false,
    quotation: false,
    leadDescription: false,
    sendQuotationToLead: false,
    addQuotationDialog: false
  });
  useEffect(() => {
    getAllLeads()
      .then(data => {
        setLeads(data.data.leads);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log('LEADS', leads);
  }, [leads]);

  const handleOpen = type => {
    setOpen({ ...open, [type]: true });
  };
  const handleQuotationOpen = () => {
    setOpen({ ...open, quotation: true });
  };
  const handleQuotationClose = () => {
    setOpen({ ...open, quotation: false });
  };

  const handleLeadDescriptionClose = () => {
    setOpen({ ...open, leadDescription: false });
  };

  const handleLeadDescriptionOpen = lead => {
    setCurrentLead(lead);
    setOpen({ ...open, leadDescription: true });
  };

  const handleClose = async lead => {
    if (lead === 'none' || lead === 'rejected') {
      setOpen({ ...open, addLead: false });
    } else {
      /**Save or update lead */
      const { user } = JSON.parse(localStorage.getItem('user'));
      lead.createdBy = user.email;
      const url =
        !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
          ? 'http://localhost:4000/api/leads/savenewlead'
          : 'https://crm.we4rent.com/api/leads/savenewlead';
      setIsLoading(true);
      // const url = `https://we4rent.herokuapp.com/api/savelead`
      let headers = new Headers();
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Origin', 'http://localhost:4000');
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(lead)
      });
      const result = await response.json();

      setIsLoading(false);
      if (result.message === 'success' || result.message === 'updated') {
        setCreatedLead(lead);
        setOpen({ ...open, addLead: false, sendQuotationToLead: true });
        handleChanges();
      } else {
        alert('There was a problem. Cannot save lead');
        setOpen({ ...open, addLead: false });
      }
    }
  };
  return (
    <ViewContainer container>
      {/* <Grid container spacing={4}>

            </Grid> */}
      <Grid container style={{ padding: '8px 0px', marginTop: '32px' }} justify='space-between'>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <AddAndSaveButton onClickFunction={handleOpen} addOrSave='addLead' buttonName='Add new lead' />
            </Grid>
            <Grid item>
              <AddAndSaveButton buttonName='New Quote' onClickFunction={handleOpen} addOrSave='quotation' />
            </Grid>

            <Grid item></Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading || !leads || leads.length === 0 ? (
        <ViewProgressBar />
      ) : (
        <LeadsTable
          handleLeadDescriptionOpen={handleLeadDescriptionOpen}
          leads={
            state ? leads.filter(item => isSameDate(new Date(item.lead.creationDate), new Date())) : leads
          }
          numberOfNewLeads={numberOfNewLeads}
        />
      )}

      <Dialog
        open={open.sendQuotationToLead}
        onClose={() => setOpen({ ...open, sendQuotationToLead: false })}
      >
        <DialogContent>
          <DialogContentText>Do you want to send a quotation to this lead</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen({ ...open, sendQuotationToLead: false })} color='primary'>
            No
          </Button>
          <Button
            onClick={() =>
              setOpen({
                ...open,
                sendQuotationToLead: false,
                addQuotationDialog: true
              })
            }
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <AddQuotationDialog
        createdLead={createdLead}
        open={open.addQuotationDialog}
        handleClose={() => setOpen({ ...open, addQuotationDialog: false })}
      />

      <AddNewLead open={open.addLead} handleClose={handleClose} />
      <AddQuotation open={open.quotation} handleQuotationClose={handleQuotationClose} />
      {currentLead && (
        <LeadDescription
          handleQuotationOpen={handleQuotationOpen}
          handleQuotationClose={handleQuotationClose}
          lead={currentLead}
          open={open}
          handleLeadDescriptionClose={handleLeadDescriptionClose}
        />
      )}
    </ViewContainer>
  );
};

export default Leads;
