import { TextField, Typography } from '@material-ui/core';
import React from 'react';

const ExtrasAndInsurances = ({ order, setOrder }) => {
  const { userExtras, protections: insurances } = order;
  const handleChange = (e) => {
    const values = e.target.value.split('\n');
    const { name } = e.target;
    let items = [];

    if (name === 'userExtras') {
      values.forEach((value) => {
        items.push({
          name: value,
          price: -1,
          isPricePerRent: false,
          code: '',
          count: 1,
        });
      });
    } else {
      values.forEach((value) => {
        items.push({
          name: value,
          code: '',
          price: -1,
        });
      });
    }

    setOrder({ ...order, [name]: items });
  };
  return (
    <>
      <Typography style={{ textAlign: 'center', marginBottom: 8 }} variant="h6">
        Extras and Insurances
      </Typography>

      <TextField
        value={userExtras.map((e) => e.name).join('\n')}
        name="userExtras"
        multiline
        label="Extras"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 4 }}
        onChange={handleChange}
      />
      <TextField
        value={insurances.map((e) => e.name).join('\n')}
        multiline
        label="Insurances"
        variant="outlined"
        fullWidth
        name="protections"
        onChange={handleChange}
      />
    </>
  );
};

export default ExtrasAndInsurances;
