import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import TableComponent from './TableComponent';
const PromoTable = ({ promo, handleOpenDialog }) => {
  const [promoRows, setPromoRows] = useState(promo);
  useEffect(() => {
    setPromoRows(promo);
    // console.log('PROMO', promo);
  }, [promo]);

  return (
    <TableContainer component={TableComponent}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell align='center'>Until</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promoRows &&
            promoRows.length > 0 &&
            promoRows.map((row, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell>{format(new Date(row.from), 'dd.MM.yyyy')}</TableCell>
                  <TableCell align='center'>
                    {format(new Date(row.until.split('T')[0]), 'dd.MM.yyyy')}
                  </TableCell>
                  {/* <TableCell align='center'>
                    <StyledChip
                      label={row.promo && row.promo.isActive ? 'Yes' : 'No'}
                    />
                  </TableCell> */}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Button style={{ marginLeft: 8 }} onClick={() => handleOpenDialog('addPromoDialog', true)}>
        Add promo dates
      </Button>
    </TableContainer>
  );
};

export default PromoTable;
