const { TableBandHeader } = require('@devexpress/dx-react-grid-material-ui');

const BandCell = ({ tableColumn, style, ...restProps }) => (
  <TableBandHeader.Cell
    style={{
      ...style,
      border: '1px solid #d9c8c8',
      textAlign: 'center'
    }}
    {...restProps}
  />
);
export default BandCell;
