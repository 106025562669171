import { Grid, Paper, Typography } from '@material-ui/core';

import { HeadBlock } from 'views/CarsPriceList/styles/TopBar.styled';
import HolidaysTable from './components/HolidaysTable';
import PromoTable from './components/PromoTable';
import SeasonsTable from './components/SeasonsTable';

const EventsSections = ({
  events: { holidays, stopSale, seasons },
  promo,
  isPromo,
  handleOpenDialog,
  priceListName,
}) => {
  return (
    <Paper style={{ width: '100%', padding: 8 }}>
      <Grid container>
        <HeadBlock item xs={12} sm={4}>
          <Typography variant='h6' style={{ textAlign: 'center' }}>
            Events
          </Typography>
          <HolidaysTable holidays={holidays} stopSale={stopSale} handleOpenDialog={handleOpenDialog} />
        </HeadBlock>

        <HeadBlock item xs={12} sm={4}>
          <Typography variant='h6' style={{ textAlign: 'center' }}>
            Seasons
          </Typography>

          <SeasonsTable seasons={seasons} />
        </HeadBlock>

        <HeadBlock item xs={12} sm={4}>
          {isPromo && (
            <>
              {' '}
              <Typography variant='h6' style={{ textAlign: 'center' }}>
                {priceListName} promo dates
              </Typography>
              <PromoTable promo={promo} handleOpenDialog={handleOpenDialog} />
            </>
          )}
        </HeadBlock>
      </Grid>
    </Paper>
  );
};

export default EventsSections;
