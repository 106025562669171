import React, { forwardRef } from 'react';
import { Button, Dialog, AppBar, Toolbar, IconButton, Slide, Typography, Tabs, Tab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NewLeadForm from '../Components/NewLeadForm.jsx';
import NewSimForm from './NewSimForm.jsx';
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
// const InputItem = ({ fieldName, handleInputChange }) => (
//   <TextField autoComplete='none' fullWidth label={fieldName} onChange={handleInputChange} />
// );

const TabPanel = ({ children, value, index, ...other }) => (
  <div style={{ height: '400px' }} role='tabpanel' hidden={value !== index} {...other}>
    {value === index && <div style={{ height: '100%' }}>{children}</div>}
  </div>
);

const AddNewLead = ({ open, handleClose }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Dialog
      onBackdropClick={() => handleClose('rejected')}
      maxWidth={'md'}
      fullWidth={true}
      open={open}
      onClose={() => handleClose('rejected')}
      TransitionComponent={Transition}
    >
      <AppBar position='static'>
        <Toolbar>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <IconButton edge='start' color='inherit' onClick={() => handleClose('none')} aria-label='close'>
              <CloseIcon />
            </IconButton>

            <Typography variant='h6' style={{ color: 'white' }}>
              ADD NEW LEAD
            </Typography>
            <Button autoFocus color='inherit'>
              save
            </Button>
          </div>
        </Toolbar>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='fullWidth'
          indicatorColor='secondary'
          TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
        >
          <Tab label='Cars' />
          <Tab label='Sim' />
          <Tab label='Hotels' />
          <Tab label='Apartments' />
        </Tabs>
      </AppBar>
      <div style={{ padding: '32px' }}>
        <TabPanel value={value} index={0}>
          <NewLeadForm />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NewSimForm />
        </TabPanel>
        <TabPanel value={value} index={2}>
          Hotels here
        </TabPanel>
        <TabPanel value={value} index={3}>
          Apartments here
        </TabPanel>
      </div>
    </Dialog>
  );
};

export default AddNewLead;
