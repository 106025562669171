import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
const ButtonWrapper = styled.div`
  position: relative;
`;
const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  color: white;
  left: 40%;
  top: 20%;
`;

const LoadingButton = ({ name, isLoading, handleClick, disabled }) => {
  return (
    <ButtonWrapper>
      <Button
        variant="outlined"
        disabled={disabled}
        autoFocus
        color="inherit"
        onClick={handleClick}
        style={{ marginRight: 8 }}
      >
        {name}
      </Button>
      {isLoading && <StyledCircularProgress size={24} />}
    </ButtonWrapper>
  );
};

export default LoadingButton;
