import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';

const EditPriceListDialog = ({ setDialogOpen, open, handleEditPriceListName, currentName }) => {
  const [name, setName] = React.useState(currentName);
  React.useEffect(() => {
    setName(currentName);
  }, [currentName]);
  const handleClose = () => {
    setDialogOpen({ dialogName: 'EditPriceListDialog', isOpen: false });
  };
  return (
    <Dialog onClose={handleClose} open={open} maxWidth='lg'>
      <DialogTitle onClose={handleClose}>
        <Typography variant='h5' component='span'>
          Edit Price list name
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Container style={{ border: '1px solid', padding: 32, borderRadius: 2, borderColor: '#0000001f' }}>
          <TextField
            required
            id='priceListName'
            label='Price list name'
            variant='outlined'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color='primary'>
          Cancel
        </Button>

        <Button type='submit' autoFocus color='primary' onClick={() => handleEditPriceListName(name)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPriceListDialog;
