/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ExpandedMenuItem from './components/ExpandedMenuItem';
import CustomRouterLink from './components/CustomRouterLink';
import { Button, colors, List, ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const SidebarNav = props => {
  const apis = [
    { apiName: 'Ofran', pathname: 'ofran' },
    { apiName: 'Top Rent A Car', pathname: 'toprentacar' }
  ];
  const { pages, className, suppliers, ...rest } = props;
  const classes = useStyles();
  const [suppliersOptions] = React.useState([...new Set(suppliers)]);

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(page => {
        const { title, href } = page;

        return (
          <ListItem className={classes.item} disableGutters key={page.title}>
            {title !== 'Cars pricelist' && page.title !== 'Api Config' ? (
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={href}
              >
                <div className={classes.icon}>{page.icon}</div>
                {title}
              </Button>
            ) : (
              <ExpandedMenuItem
                options={page.title === 'Cars pricelist' ? suppliersOptions : ['Ofran', 'Top Rent A Car']}
                page={page}
              />
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
