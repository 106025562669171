import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
}));

const AccountDetails = props => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  // const [values, setValues] = useState({
  //   firstName: 'Denis',
  //   lastName: 'Ievlev',
  //   email: 'denis@we4rent.com',
  //   phone: '',
  //   state: 'Gush dan',
  //   country: 'Israel',
  // });

  // const handleChange = event => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  // const states = [
  //   {
  //     value: 'alabama',
  //     label: 'Alabama',
  //   },
  //   {
  //     value: 'new-york',
  //     label: 'New York',
  //   },
  //   {
  //     value: 'san-francisco',
  //     label: 'San Francisco',
  //   },
  // ];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete='off' noValidate>
        <CardHeader subheader='The information can be edited' title='Profile' />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                inputProps={{ readOnly: true }}
                helperText='Please specify the first name'
                label='First name'
                margin='dense'
                name='firstName'
                // onChange={handleChange}
                required
                value={user.firstName}
                variant='outlined'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label='Last name'
                margin='dense'
                name='lastName'
                // onChange={handleChange}
                required
                value={user.lastName}
                variant='outlined'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
                label='Email Address'
                margin='dense'
                name='email'
                // onChange={handleChange}
                required
                value={user.email}
                variant='outlined'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label='Phone Number'
                margin='dense'
                name='phone'
                // onChange={handleChange}
                value={user.phone}
                variant='outlined'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <TextField
                fullWidth
                label="Select State"
                margin="dense"
                name="state"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField> */}
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label='Country'
                margin='dense'
                name='country'
                // onChange={handleChange}
                required
                value={user.address.country}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color='primary' variant='contained'>
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
};

export default AccountDetails;
