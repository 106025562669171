import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import styled from 'styled-components';

const StyledSnackBar = styled(Snackbar)`
  /* .MuiAlert-root {
    background-color: #77c777;
  } */
`;
const AlertMessage = ({ isSaved: { status, type, message }, setIsSaved }) => {
  return (
    <StyledSnackBar
      open={status}
      autoHideDuration={5000}
      onClose={() => setIsSaved({ status: false, type: 'success', message: '' })}
    >
      <Alert severity={type}>{message}</Alert>
    </StyledSnackBar>
  );
};

export default AlertMessage;
