import { Button, Chip, Input, MenuItem, Paper, Select, useTheme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import { StyledChip } from './styles/SellTable.styled';

const SellTable = ({ simCards }) => {
  const { palette } = useTheme();
  const [columns] = useState([
    { name: 'orderDate', title: 'Order date' },
    {
      name: 'isLead',
      title: 'Lead'
    },
    {
      name: 'phoneNumber',
      title: 'Sim phone number'
    },
    {
      name: 'simCardOrderId',
      title: 'Order ID'
    },

    { name: 'firstName', title: 'First Name' },
    { name: 'lastName', title: 'Last Name' },
    { name: 'customerPhoneNumber', title: "Customer's phone" }
  ]);
  const [booleanColumns] = useState(['isLead']);
  const [dateColumns] = useState(['orderDate']);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(
      simCards.map((sim, id) => {
        return { ...sim, id };
      })
    );
  }, [simCards]);
  const DateFormatter = ({ value }) => {
    if (value) {
      const date = new Date(value).toLocaleDateString('en-GB');
      return date;
    } else return '';
  };

  const DateTypeProvider = props => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

  const BooleanEditor = ({ value, onValueChange }) => (
    <Select
      input={<Input />}
      value={value ? 'Yes' : 'No'}
      onChange={event => onValueChange(event.target.value === 'Yes')}
      style={{ width: '100%' }}
    >
      <MenuItem value='Yes'>Yes</MenuItem>
      <MenuItem value='No'>No</MenuItem>
    </Select>
  );
  const BooleanFormatter = ({ value }) => (
    <StyledChip label={value ? 'Yes' : 'No'} bg={value ? palette.success.main : '#E0E0E0'} />
  );
  const BooleanTypeProvider = props => (
    <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} {...props} />
  );

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        }))
      ];
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(row => !deletedSet.has(row.id));
    }
    setRows(changedRows);
  };
  return (
    <Paper>
      <Grid rows={rows} columns={columns}>
        <DateTypeProvider for={dateColumns} />
        <BooleanTypeProvider for={booleanColumns} />
        <Table />
        <TableHeaderRow />
        <EditingState onCommitChanges={commitChanges} />
        <TableEditRow />
        <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
      </Grid>
    </Paper>
  );
};

export default SellTable;
