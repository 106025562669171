import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { savePromoDates } from 'apiRequests';
import React, { useState, useEffect } from 'react';

const AddPromoDialog = ({ open, handleOpenDialog, priceListID, setChanges }) => {
  const [from, setFrom] = useState(new Date());
  const [until, setUntil] = useState(new Date());

  const handleAddPromoDates = () => {
    savePromoDates(from, until, priceListID)
      .then(res => {
        alert(res.data.message);
        setChanges(prevState => ({
          changes: !prevState.changes
        }));
      })
      .catch(err => {
        console.log(err);
        alert(err.response.data.message);
      })
      .finally(handleOpenDialog('addPromoDialog', false));
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle style={{ textAlign: 'center' }}>Choose dates</DialogTitle>
      <DialogContent style={{ paddingBottom: 16 }}>
        <Container style={{ textAlign: 'center' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={1} direction='row'>
              <Grid item xs={12} md={6}>
                <DatePicker
                  fullWidth
                  style={{ marginRight: 16 }}
                  label='From'
                  value={from}
                  onChange={setFrom}
                  animateYearScrolling
                  inputVariant='outlined'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  fullWidth
                  label='Until'
                  value={until}
                  onChange={setUntil}
                  animateYearScrolling
                  inputVariant='outlined'
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpenDialog('addPromoDialog', false)}>Cancel</Button>
        <Button onClick={handleAddPromoDates}>SAVE</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPromoDialog;
