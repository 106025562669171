import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const tokens = localStorage.getItem('jwt-token')
  ? JSON.parse(localStorage.getItem('jwt-token'))
  : null;

let accessToken = tokens ? tokens.token : null;
let refreshToken = tokens ? tokens.refreshToken : null;

export const hostname =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'http://crm.localhost:4000'
    : 'https://crm.we4rent.com';
const headers = {
  Authorization: `Bearer ${accessToken}`,
  'X-Auth-Token': accessToken,
  'content-type': 'application/json',
};
const axiosInstance = axios.create({ baseURL: hostname, headers });
axiosInstance.interceptors.request.use(async (req) => {
  if (!accessToken || !refreshToken) {
    const tokens = JSON.parse(localStorage.getItem('jwt-token'));
    accessToken = tokens ? tokens.token : null;
    refreshToken = tokens ? tokens.refreshToken : null;
    req.headers.Authorization = `Bearer ${accessToken}`;
  }
  const user = jwtDecode(accessToken);
  if (user.exp < new Date().getTime() / 1000) {
    //expired
    console.log('EXPIRED TOKEN');
    const {
      data: { accessToken: token, refreshToken: refToken },
    } = await axios.post(`${hostname}/api/auth/refresh-token`, {
      refreshToken,
    });

    localStorage.setItem(
      'jwt-token',
      JSON.stringify({ token, refreshToken: refToken })
    );
    req.headers['X-Auth-Token'] = token;
    return req;
  } else {
    console.log(req.headers);
    return req;
  }
});

export default axiosInstance;
