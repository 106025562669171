const auth = {
  isAuthenticated: false,
  authenticate() {
    auth.isAuthenticated = true;
  },
  signOut() {
    localStorage.removeItem('jwt-token');
    localStorage.removeItem('user');
    auth.isAuthenticated = false;
  }
};
export default auth;
