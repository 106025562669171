import React from 'react'
import { Grid } from '@material-ui/core'

const Reject = () => {
    return (
        <Grid container spacing={1} style={{width:'1280px', height:'800px'}}>
            <Grid item >Reject</Grid>
        </Grid>
    )
}

export default Reject
