import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import validate from 'validate.js';

import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import Store from 'sharedState/authState';
// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw
// });

validate.validators = {
  ...validate.validators,
  ...validators
};
// let token = {};

console.log(theme);
const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Store>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Store>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
export default App;
