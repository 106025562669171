import { Accordion, AccordionDetails, AccordionSummary, Link, List, ListItem } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, useTheme } from '@material-ui/styles';
import CustomRouterLink from './CustomRouterLink';
import { colors } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-elevation1 {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;
const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const ExpandedMenuItem = ({ options, page }) => {
  const classes = useStyles();
  const Icon = () => {
    if (page.href === '/api-config') return <CodeIcon className={classes.icon} />;
    else return <LocalOfferIcon className={classes.icon} />;
  };
  return (
    <StyledAccordion>
      <AccordionSummary style={{ paddingLeft: '8px' }} expandIcon={<ExpandMoreIcon />}>
        <>
          <Icon />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#37474f',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 0,
              fontFamily: 'Roboto'
            }}
          >
            {page.title}
          </div>
        </>
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: 0, paddingBottom: 0 }}>
        <List dense>
          {options &&
            options.length > 0 &&
            options.map((option, idx) => (
              <ListItem key={idx}>
                <Link
                  style={{ color: '#000000', textDecoration: 'none' }}
                  to={{
                    pathname:
                      page.title === 'Cars pricelist'
                        ? page.href
                        : option === 'Ofran'
                        ? '/api-config/ofran'
                        : '/api-config/toprentacar',
                    OfflineAudioCompletionEvent
                  }}
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                >
                  {option}
                </Link>
              </ListItem>
            ))}
        </List>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default ExpandedMenuItem;
