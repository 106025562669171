import React, { useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { AuthContext } from './sharedState/authState';
import ApiConfig from './views/ApiConfig/ApiConfig.jsx';
import {
  Dashboard as DashboardView,
  // ProductList as ProductListView,
  Contacts as ContactsView,
  Account as AccountView,
  // Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Orders as OrdersView,
  Leads as LeadsView,
  Quotes as QuotesView,
  CarsPriceList as CarsPriceListView,
  Locations as LocationsView,
  SimCards as SimCardsView,
  Events as EventsView
} from './views';

const Routes = () => {
  const [authState] = useContext(AuthContext);
  return (
    <Switch>
      <Redirect exact from='/' to={authState.isAuthenticated ? '/dashboard' : '/sign-in'} />
      {/**Private routes */}
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path='/dashboard'
        isPublic={false}
      />
      <RouteWithLayout component={EventsView} exact layout={MainLayout} path='/events' isPublic={false} />
      <RouteWithLayout
        component={LocationsView}
        exact
        layout={MainLayout}
        path='/locations'
        isPublic={false}
      />
      <RouteWithLayout component={ApiConfig} path='/api-config/:api' layout={MainLayout} isPublic={false} />
      <RouteWithLayout
        component={SimCardsView}
        exact
        layout={MainLayout}
        path='/sim-cards'
        isPublic={false}
      />
      <RouteWithLayout component={ContactsView} exact layout={MainLayout} path='/contacts' isPublic={false} />
      <RouteWithLayout component={OrdersView} exact layout={MainLayout} path='/orders' isPublic={false} />
      <RouteWithLayout component={LeadsView} exact layout={MainLayout} path='/leads' isPublic={false} />
      <RouteWithLayout component={QuotesView} exact layout={MainLayout} path='/quotes' isPublic={false} />
      {/**Public routes */}
      <Route exact path='/sign-up'>
        <Redirect to='/sign-in' />
      </Route>
      <RouteWithLayout component={SignUpView} exact layout={MinimalLayout} path='/sign-up' isPublic={true} />
      <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path='/sign-in' isPublic={true} />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path='/not-found'
        isPublic={true}
      />
      <RouteWithLayout component={AccountView} exact layout={MainLayout} path='/account' isPublic={false} />
      <RouteWithLayout
        component={CarsPriceListView}
        exact
        layout={MainLayout}
        path='/carspricelist'
        isPublic={false}
      />

      {/* <RouteWithLayout component={IconsView} exact layout={MainLayout} path='/icons' /> */}

      <Redirect to='/not-found' />
    </Switch>
  );
};

export default Routes;
