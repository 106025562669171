import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { onSave } from '../../../helpers';
import { toggleButton } from '../../../assets/reusableItems/items';

import {
  Grid,
  TableHeaderRow,
  TableInlineCellEditing,
  Toolbar,
  SearchPanel,
  TableEditRow,
  ExportPanel,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

import {
  SortingState,
  IntegratedSorting,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  SearchState,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
  VirtualTableState,
} from '@devexpress/dx-react-grid';
import { Paper, Select, MenuItem } from '@material-ui/core';
// import OrderDescription from './OrderDescription/OrderDescription';
import { GreenCheckbox } from '../styles/OrdersTable.styled';
import { reducer } from '../helpers/reducer';
import { hostname } from 'helpers/config';
import AddNewOrder from './AddNewOrder/AddNewOrder';

const VIRTUAL_PAGE_SIZE = 20;
// const MAX_ROWS = 80;
const URL = `${hostname}/api/getorders`;
// const getRowId = (row) => row.Id;

const buildQueryString = (skip, take) => `${URL}?skip=${skip}&take=${take}`;

const initialState = {
  rows: [],
  skip: 0,
  requestedSkip: 0,
  take: VIRTUAL_PAGE_SIZE * 2,
  totalCount: 0,
  loading: false,
  lastQuery: '',
};

const OrdersTable = ({ orders, setChanges, changes }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   console.log('STATE', state);
  // }, [state]);

  const getRemoteRows = (requestedSkip, take) => {
    dispatch({ type: 'START_LOADING', payload: { requestedSkip, take } });
  };

  const [pageSizes] = useState([10, 15, 20, 0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState();
  // const [rows, setRows] = useState(orders);
  // useEffect(() => {
  //   console.log('SELECTED ORDER', order);
  // }, [order]);
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const deleteOrder = async (orderId, firstName, lastName) => {
    const changedRows = rows.filter((row) => row.orderId !== orderId);
    const start =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? 'http://localhost:4000'
        : 'https://crm.we4rent.com';
    const url = `${start}/api/orders/deleteorderbyorderidandname?orderId=${orderId}&firstName=${firstName}&lastName=${lastName}`;
    const data = await fetch(url);
    const res = await data.json();
    if (res.message.status === 'success') {
      // setRows(changedRows); //TODO
    }
  };
  // useEffect(() => {
  //   console.log('ORDER IS', order);
  // }, [order]);
  // useEffect(() => {
  //   console.log('ROWS', rows);
  // }, [rows]);
  const handleClose = () => {
    setOrder();
    setOpen(false);
  };
  const handleOpen = (orderId) => {
    const selectedOrder = rows.find((row) => row.orderId === orderId);
    setOrder(selectedOrder);
    setOpen(true);
  };

  const handleStatusChange = () => {
    console.log('changed');
  };
  const columns = [
    { name: 'orderDate', title: 'Order Date' },
    { name: 'orderId', title: 'Order Number' },
    {
      name: 'name',
      title: 'Full Name',
      getCellValue: (row) =>
        row.driverDetails
          ? row.driverDetails.firstName + ' ' + row.driverDetails.lastName
          : '',
    },
    {
      name: 'email',
      title: 'Email',
      getCellValue: (row) => (row.driverDetails ? row.driverDetails.email : ''),
    },
    {
      name: 'phone',
      title: 'Phone',
      getCellValue: (row) => (row.driverDetails ? row.driverDetails.phone : ''),
    },
    { name: 'attachFile', title: 'Attach File' },
    { name: 'status', title: 'Status' },
  ];
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'attachFile', filteringEnabled: false },
    { columnName: 'orderDate', filteringEnabled: false },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'orderDate', width: 200 },
    { columnName: 'orderId', width: 100 },
    { columnName: 'status', width: 100 },
    { columnName: 'name', width: 130 },
    { columnName: 'phone', width: 200 },
    { columnName: 'attachFile', width: 100 },
    { columnName: 'email', width: 200 },
  ]);

  const toolbarRoot = (props) => (
    <Toolbar.Root {...props} style={{ flexDirection: 'row-reverse' }} />
  );

  const OrderIdCell = ({ value, style, ...restProps }) => (
    <VirtualTable.Cell
      onClick={() => handleOpen(value)}
      {...restProps}
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
    >
      {value}
    </VirtualTable.Cell>
  );
  const AttachFileCell = (props) => (
    <VirtualTable.Cell {...props}>
      <input accept="image/*" id="icon-button-file" type="file" />
      {/* <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <AttachmentIcon />
                </IconButton>
            </label> */}
    </VirtualTable.Cell>
  );

  const StatusCell = ({
    value,
    row: { orderId, firstName, lastName },
    ...restProps
  }) => {
    return (
      <VirtualTable.Cell
        {...restProps}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Select
          style={{ marginRight: '8px', width: '100px' }}
          value={value}
          onChange={handleStatusChange}
        >
          <MenuItem value={'ordered'}>Ordered</MenuItem>
          <MenuItem value={'confirmed'}>Confirmed</MenuItem>
          <MenuItem value={'onRequest'}>On request</MenuItem>
          <MenuItem value={'denied'}>Denied</MenuItem>
          <MenuItem value={'cancelled'}>Cancelled</MenuItem>
        </Select>
        <GreenCheckbox checked={value === 'confirmed'} />
        <DeleteForeverIcon
          style={{ cursor: 'pointer' }}
          onClick={() => deleteOrder(orderId, firstName, lastName)}
        />
      </VirtualTable.Cell>
    );
  };
  const Cell = ({ onClick, ...restProps }) => {
    const { column } = restProps;

    if (column.name === 'orderId') return <OrderIdCell {...restProps} />;
    if (column.name === 'attachFile') return <AttachFileCell {...restProps} />;
    if (column.name === 'status') return <StatusCell {...restProps} />;
    return <VirtualTable.Cell tabIndex={0} onFocus={onClick} {...restProps} />;
  };

  const commitChanges = ({ deleted, changed }) => {
    let changedRows;

    if (changed) {
      changedRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.id));
    }
    // setRows(changedRows); //TODO
  };
  const loadData = () => {
    const { requestedSkip, take, lastQuery, loading } = state;
    const query = buildQueryString(requestedSkip, take);
    if (query !== lastQuery && !loading) {
      dispatch({ type: 'FETCH_INIT' });
      fetch(query)
        .then((response) => response.json())
        .then((res) => {
          // console.log('UPDATING....');
          dispatch({
            type: 'UPDATE_ROWS',
            payload: {
              skip: requestedSkip,
              rows: res.orders,
              totalCount: res.totalCount ? res.totalCount : totalCount,
            },
          });
        })
        .catch(() => dispatch({ type: 'REQUEST_ERROR' }));
      dispatch({ type: 'UPDATE_QUERY', payload: query });
    }
  };

  useEffect(() => {
    if (state.requestedSkip + state.take !== state.totalCount) {
      loadData();
    }
  });
  const { rows, skip, totalCount, loading } = state;

  /**Renaming the date columns after sorting */
  const [dateColumns] = useState(['orderDate']);
  const DateFormatter = ({ value }) => new Date(value).toLocaleString('en-GB');
  /** */

  const DateTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );
  return (
    <React.Fragment>
      <Paper>
        <>
          <Grid rows={rows} columns={columns}>
            <DateTypeProvider for={dateColumns} />
            <EditingState onCommitChanges={commitChanges} />
            <SortingState
              defaultSorting={[{ columnName: 'orderDate', direction: 'desc' }]}
            />
            <IntegratedSorting />

            <FilteringState
              defaultFilters={[]}
              columnExtensions={filteringStateColumnExtensions}
            />
            <SearchState />
            <VirtualTableState
              loading={loading}
              totalRowCount={totalCount}
              pageSize={VIRTUAL_PAGE_SIZE}
              skip={skip}
              getRows={getRemoteRows}
            />

            <IntegratedFiltering />
            <VirtualTable
              cellComponent={Cell}
              columnExtensions={tableColumnExtensions}
            />
            <TableHeaderRow showSortingControls />

            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <PagingPanel pageSizes={pageSizes} />
            <IntegratedPaging />

            <TableEditRow />

            <Toolbar rootComponent={toolbarRoot} />

            <ExportPanel
              startExport={startExport}
              toggleButtonComponent={toggleButton}
            />

            <SearchPanel />

            <TableInlineCellEditing
              startEditAction={'click'}
              selectTextOnEditStart={true}
            />
          </Grid>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            columns={columns}
            onSave={(workbook) => onSave(workbook, 'orders')}
          />
        </>
      </Paper>
      {order && (
        <AddNewOrder open={open} editOrder={order} setOpen={handleClose} /> //also for edit order
      )}
    </React.Fragment>
  );
};

export default OrdersTable;
