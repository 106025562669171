import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';

const ChangeCommissionFeeDialog = ({ open: { status, api }, handleClose, handleCommissionChange }) => {
  const [value, setValue] = React.useState(api.commissionFeePercent);
  return (
    <Dialog open={status} onClose={handleClose}>
      <DialogTitle>
        <Typography variant='h5'>Change commission fee of {api.apiName}</Typography>{' '}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ width: 200 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ padding: 8 }}>Commission fee </div>
            <TextField
              type='number'
              style={{ flex: 2, padding: 8 }}
              variant='outlined'
              size='small'
              value={value}
              onChange={({ target: { value } }) => setValue(value)}
            />
          </div>{' '}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleCommissionChange(api.apiType, value)} autoFocus>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeCommissionFeeDialog;
