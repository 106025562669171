import React, { useState, useRef, useCallback } from 'react';
import { Paper, CircularProgress } from '@material-ui/core';
import { onSave, isSameDate } from '../../helpers/utils';
import { toggleButton } from '../../assets/reusableItems/items';
import {
  SortingState,
  IntegratedSorting,
  EditingState,
  SearchState,
  DataTypeProvider,
  PagingState,
  IntegratedPaging
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  TableEditRow,
  TableEditColumn,
  PagingPanel,
  ExportPanel
} from '@devexpress/dx-react-grid-material-ui';

import { useEffect } from 'react';
/**
 * {"lead":
 * {"isLead":true,"source":"http://localhost:4000/landing/location/tel-aviv?firstName=Denis&lastName=Ievlev&email=denis%40we4rent.com&phon4ik=0547275917&typing=HEU","comment":[{"_id":"5edafa95d36a9b493d31200c","date":"2020-06-06T02:08:21.916Z","message":"HEY"},{"_id":"5edbcd7a436126823cc8235e","date":"2020-06-06T17:08:10.528Z","message":"HEY FORD EDGE PLEASE","selectedCar":"Ford Edge"},{"_id":"5edbcf002c55e2862b9aa4d8","date":"2020-06-06T17:14:40.503Z","message":"hey","selectedCar":"Ford Edge"},{"_id":"5edbcf022c55e2862b9aa4da","date":"2020-06-06T17:14:42.525Z","message":"hey","selectedCar":"Ford Edge"},{"_id":"5edbcf022c55e2862b9aa4d9","date":"2020-06-06T17:14:42.322Z","message":"hey","selectedCar":"Ford Edge"},{"_id":"5edbcf032c55e2862b9aa4dc","date":"2020-06-06T17:14:43.141Z","message":"hey","selectedCar":"Ford Edge"},{"_id":"5edbcf022c55e2862b9aa4db","date":"2020-06-06T17:14:42.932Z","message":"hey","selectedCar":"Ford Edge"},{"_id":"5edbcf032c55e2862b9aa4dd","date":"2020-06-06T17:14:43.343Z","message":"hey","selectedCar":"Ford Edge"}]},"additionalPhoneNumbers":[],"additionalEmails":["denis.ievlev@gmail.com","denis.ievlev@gmail.com","denis.ievlev@gmail.com","denis.ievlev@gmail.com","denis.ievlev@gmail.com","denis.ievlev@gmail.com","denis.ievlev@gmail.com"],"isSubscribed":false,"_id":"5edafa95d36a9b493d31200b","email":"denis@we4rent.com","firstName":"Denis","lastName":"Ievlev","phone":"0547275917","order":[],"__v":7}
 */

const LeadsTable = ({ leads, handleLeadDescriptionOpen, numberOfNewLeads }) => {
  // const [lead, setLead] = useState();
  const [rows, setRows] = useState(leads);
  const [pageSizes] = useState([10, 15, 20, 0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const DateFormatter = ({ value }) => new Date(value).toLocaleString('en-GB');

  useEffect(() => console.log(rows), [rows]);

  // const [selection, setSelection] = useState();

  const columns = [
    {
      name: 'creationDate',
      title: 'Lead Date',
      getCellValue: row => row.lead.creationDate
    },
    { name: 'fullName', title: 'Full Name' },
    { name: 'email', title: 'Email' },
    { name: 'phone', title: 'Phone' },
    {
      name: 'product',
      title: 'Product type',
      getCellValue: row => row.lead.product || 'unknown'
    }
  ];
  const [tableColumnExtensions] = useState([
    { columnName: 'creationDate', width: 'auto' },
    { columnName: 'firstName', width: '20%' },
    { columnName: 'lastName', width: '20%' },
    { columnName: 'email', width: '30%' }
  ]);
  const [dateColumns] = useState(['creationDate']);

  const Square = () => (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div
        style={{
          height: '25px',
          width: '25px',
          backgroundColor: '#b3e5fc',
          marginRight: '4px'
        }}
      />
      Today leads
    </div>
  );
  const toolbarRoot = props => (
    <Toolbar.Root {...props} style={{ flexDirection: 'row-reverse' }}>
      <Square />
      {props.children}
    </Toolbar.Root>
  );

  const DateTypeProvider = props => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

  const TableRow = ({ row, ...restProps }) => {
    return (
      <Table.Row
        {...restProps}
        onClick={() => handleLeadDescriptionOpen(row)}
        style={{
          cursor: 'pointer',
          backgroundColor: isSameDate(new Date(row.lead.creationDate), new Date()) ? '#b3e5fc' : null
        }}
      />
    );
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        }))
      ];
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(row => !deletedSet.has(row.id));
    }
    setRows(changedRows);
  };
  return (
    <Paper>
      {rows.length > 0 ? (
        <>
          <Grid rows={rows} columns={columns}>
            {/* <VirtualTable /> */}
            <SearchState />
            <EditingState onCommitChanges={commitChanges} />
            <DateTypeProvider for={dateColumns} />

            <SortingState defaultSorting={[{ columnName: 'creationDate', direction: 'desc' }]} />
            <IntegratedSorting />

            {/**TOOLBAR */}
            <Toolbar rootComponent={toolbarRoot} />
            <ExportPanel startExport={startExport} toggleButtonComponent={toggleButton} />
            <SearchPanel />
            {/**END TOOLBAR */}

            {/**PAGING */}
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            {/**END PAGING */}

            <Table rowComponent={TableRow} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />

            {/**Edit and delete column */}
            <TableEditRow />
            <TableEditColumn showEditCommand showDeleteCommand />
            {/** */}

            <PagingPanel pageSizes={pageSizes} />
          </Grid>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            columns={columns}
            onSave={workbook => onSave(workbook, 'leads')}
          />
        </>
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
};

export default LeadsTable;
