import React from 'react';
import { Grid } from '@material-ui/core';
import PersonalDetailsForm from './PersonalDetailsForm';

const NewSimForm = () => {
  const handleSubmit = (e, data) => {
    e.preventDefault();
    console.log(data);
  };
  return (
    <Grid container justify='space-between' spacing={1} style={{ maxWidth: '800px' }}>
      <PersonalDetailsForm formId='simcard-form' handleSubmit={handleSubmit} issimcard={true} />
    </Grid>
  );
};

export default NewSimForm;
