import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import React from 'react';

const PopupEditing = React.memo(({ popupComponent: Popup }) => (
  <Plugin>
    <Template name='popupEditing'>
      <TemplateConnector>
        {(
          { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
          {
            changeRow,
            changeAddedRow,
            commitChangedRows,
            commitAddedRows,
            stopEditRows,
            cancelAddedRows,
            cancelChangedRows
          }
        ) => {
          const isNew = addedRows.length > 0;
          let editedRow;
          let rowId;
          if (isNew) {
            rowId = 0;
            editedRow = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter(row => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const processValueChange = ({ target: { name, value } }) => {
            // console.log('Name', name, 'Value', value);
            // console.log('EDITED ROW', editedRow);
            if (name === 'FC' || name === 'TW') {
              const pc = [...editedRow.prices.protection_coverage];
              let index;
              index = editedRow.prices.protection_coverage.findIndex(p => p.code === name);
              pc[index].price = value;
              editedRow = { ...editedRow, prices: { ...editedRow.prices, protection_coverage: pc } };
            }
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name)
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;

          const applyChanges = () => {
            console.log("APPLYING", isNew, rowIds)
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
            }
          };

          const cancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return (
            <Popup
              open={open}
              row={editedRow}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name='root'>
      <TemplatePlaceholder />
      <TemplatePlaceholder name='popupEditing' />
    </Template>
  </Plugin>
));

export default PopupEditing;
