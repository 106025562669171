import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { LatestOrders, InformationButton } from './components';
import { LeadsContext, NewOrdersContext } from 'sharedState/authState';
import ApiManaging from './components/ApiManaging/ApiManaging';
import { getNumberOfNewOrders } from 'apiRequests';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    height: '100%'
  },
  informationCards: {
    maxHeight: 80
  }
}));

const Dashboard = () => {
  const [numberOfNewOrders, setNumberOfNewOrders] = useState(0);
  const [leads] = React.useContext(LeadsContext);
  const [apisStatus, setApisStatus] = useState();
  const newLeads = leads[1];

  useEffect(() => {
    // console.log('GETTING NEW ORDERS');
    getNumberOfNewOrders()
      .then(({ data }) => {
        // console.log('DA', data);
        setNumberOfNewOrders(data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/**
       * Information section
       */}
      <Grid container spacing={4}>
        <Grid item lg={3} sm={3} xl={3} xs={12} className={classes.informationCards}>
          <InformationButton title={'new leads'} number={newLeads} link='/leads' />
        </Grid>
        <Grid item lg={3} sm={3} xl={3} xs={12} className={classes.informationCards}>
          <InformationButton title={'new orders'} number={numberOfNewOrders} link='/orders' />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <LatestOrders />
        </Grid>
      </Grid>
      {/**APIs status section */}
      <Grid container>
        <Grid item lg={4}>
          <ApiManaging />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
