export const nameMapping = (name, language) => {
    const isEnglish = language === 'en'
    switch (name) {
        case 'childBoosterPrice': return isEnglish ? 'Child Booster Seat' : "Детское автокресло люлька"
        case 'childSeatPrice': return isEnglish ? "Child Seat" : "Детское автокресло для детей"
        case 'additionalDriverPrice': return isEnglish ? 'Additional driver' : 'Дополнительный водитель'
        case 'toddlerSeat': return 'Toddler seat'
        case 'simCard': return isEnglish ? 'Sim card' : 'Сим карта'
        case 'airportFee': return isEnglish ? "Airport fee" : "Налог аэропорта"
        case 'roadsafeAssistance': return 'Road safe assistance'
        case 'underageDriver': return isEnglish ? 'Underage driver' : "Молодой водитель"
        case 'dropOffService': return 'Drop off service'
        case 'CSI': return isEnglish ? "Child seat for infant" : "Детское автокресло люлька"
        default: return name
    }
}

