import React, { useState, useEffect } from 'react';
import { ViewContainer, AddAndSaveButton, ViewProgressBar } from 'assets/reusableItems/items';
import { Grid } from '@material-ui/core';
import QuotesTable from './Components/QuotesTable';
import { orderURL } from 'helpers';
import axios from 'axios';
import OrderDescription from 'views/Orders/Components/OrderDescription/OrderDescription';
import AddQuotationDialog from './Components/AddQuotationDialog';
import { ChangesContext, CustomersContext } from 'sharedState/authState';

const Quotes = () => {
  const [open, setOpen] = useState({
    status: false,
    quotation: {}
  });
  const [addQuotationOpen, setAddQuotationOpen] = useState(false);
  const [customers, setCustomers] = React.useContext(CustomersContext);
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [changes] = React.useContext(ChangesContext);

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  const handleQuoteDescriptionOpen = quotation => {
    setOpen({
      status: true,
      quotation
    });
  };
  useEffect(() => {
    customers.forEach(customer => {
      if (customer.order.length > 0) {
        customer.order.forEach(quote => {
          if (quote.isQuotation) {
            tempQuotes.push({
              ...quote,
              customerId: customer._id,
              ...quote.driverDetails,
              // ...quote.quotation,
              fullName: customer.firstName + ' ' + customer.lastName
            });
          }
        });
      }
    });
    setQuotes(tempQuotes);
  }, [customers]);
  let tempQuotes = [];
  return (
    <ViewContainer>
      <Grid container style={{ padding: '8px 0px', marginTop: '32px' }}>
        <Grid item>
          <AddAndSaveButton
            buttonName='New Quote'
            onClickFunction={() => setAddQuotationOpen(true)}
            addOrSave='quotation'
          />
        </Grid>
      </Grid>

      {isLoading || quotes.length === 0 ? (
        <ViewProgressBar />
      ) : (
        <QuotesTable quotes={quotes} handleQuoteDescriptionOpen={handleQuoteDescriptionOpen} />
      )}

      {open.status && (
        <OrderDescription
          open={open.status}
          handleClose={handleClose}
          order={open.quotation}
          isOrder={false}
        />
      )}

      <AddQuotationDialog open={addQuotationOpen} handleClose={() => setAddQuotationOpen(false)} />
    </ViewContainer>
  );
};

export default Quotes;
