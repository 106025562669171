const suppliersImages = [
    { name: "Europcar", value: "europcar.svg" },
    { name: "Alamo", value: "alamo.svg" },
    { name: "Avis", value: "avis.svg" },
    { name: "Budget", value: "budget.svg" },
    { name: "Dollar", value: "dollar.svg" },
    { name: "Enterprise", value: "enterprise.svg" },
    { name: "Herz", value: "herz.svg" },
    { name: "Thrifty", value: "thrifty.svg" }
];
export default suppliersImages;  