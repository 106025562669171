export const hostname =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'http://crm.localhost:4000'
    : 'https://crm.we4rent.com';

export const orderURL = (nodeEnvironment, params) => {
  return `${hostname}/api/orders/` + params;
};
export const quotationURL = params => {
  return `${hostname}/api/quotation/` + params;
};
export const carsPricesURL = params => {
  return `${hostname}/api/carsprices/getcarsprices?country=il&supplier=europcar`;
};

