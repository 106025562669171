import axios from 'axios';
import { orderURL, carsPricesURL } from 'helpers';
import axiosInstance from 'helpers/axiosInstance';
import { hostname } from 'helpers/config';

const userToken = JSON.parse(localStorage.getItem('jwt-token'));

const headers = {
  Authorization: `Bearer ${userToken ? userToken.token : ''}`,
  'X-Auth-Token': userToken ? userToken.token : undefined,
  'content-type': 'application/json'
};
export const getAllCountries = () => {
  return axios.get(`${hostname}/api/getallcountries`, { headers });
};
export const getAllCustomers = token => {
  if (!headers['X-Auth-Token']) {
    headers['X-Auth-Token'] = token;
  }
  // console.log(headers);
  const url = orderURL(process.env.NODE_ENV, 'getallcustomers');
  return axios.get(url, { headers });
};

export const getCarsPrices = () => {
  // if (!headers['X-Auth-Token']) {
  //   headers['X-Auth-Token'] = userToken.token;
  //   headers.Authorization = `Bearer ${userToken.token}`;
  // }
  const url = carsPricesURL();
  return axiosInstance.get(url);
};
export const changeCar = (priceListID, car) => {
  const url = `${hostname}/api/editcar`;
  return axios.put(url, { priceListID, car }, { headers });
};
export const editPriceListName = (priceListID, newName) => {
  const url = `${hostname}/api/editpricelistname`;
  return axios.put(url, { newName, priceListID }, { headers });
};
export const changePriceListVisibility = (priceListID, isActive, currency, isReligious) => {
  return axios.get(
    `${hostname}/api/changePriceListVisibility?priceListID=${priceListID}&isActive=${isActive}&currency=${currency}&isReligious=${isReligious}`,
    {
      headers
    }
  );
};
export const changePromoStatus = (_id, status) => {
  return axios.get(`${hostname}/api/changepromostatus?status=${status}&pricelistid=${_id}`, { headers });
};

export const deletePriceList = id => {
  return axios.get(`${hostname}/api/deletePriceList?_id=${id}`, { headers });
};

export const createNewPriceList = (priceListName, isActive, isReligious, currency, supplierName, isPromo) => {
  return axios.get(
    `${hostname}/api/createpricelist?priceListName=${priceListName}&isActive=${isActive}&isReligious=${isReligious}&currency=${currency}&supplierName=${supplierName}&isPromo=${isPromo}`,
    { headers }
  );
};
export const changeCarPrice = (_id, parametersToSave) => {
  return axios.put(
    carsPricesURL('changecarprice'),
    {
      _id,
      parametersToSave
    },
    { headers }
  );
};
export const setStopSalesDates = (country, supplier, from, until) => {
  return axios.post(`${hostname}/api/setstopsalesdates`, {
    country,
    supplier,
    from,
    until
  });
};
export const savePromoDates = (from, until, priceListID) => {
  const dateFrom = from.toISOString().split('T')[0] + 'T00:00:00Z';
  const dateUntil = until.toISOString().split('T')[0] + 'T23:59:59Z';
  return axios.post(`${hostname}/api/savepromodates`, {
    dateFrom,
    dateUntil,
    priceListID
  });
};
export const issueVoucher = order => {
  return axios.post(`${hostname}/api/issuevoucher`, { order }, { headers });
};
export const cancelOrder = orderNumber => {
  return axios.post(`${hostname}/api/cancelorder`, { orderNumber });
};
export const getCarsImages = () => {
  return axios.get(`${hostname}/api/getcarsimages`, { headers });
};
/**
 *
 * @param {Number} apiType
 * @param {Number} commissionFee
 * @returns
 */
export const changeCommissionFee = (apiType, commissionFee) => {
  return axios.get(`${hostname}/api/changecommissionfee?apiType=${apiType}&commissionFee=${commissionFee}`);
};

export const getAllCountryLocations = countryCode => {
  return axios.get(`${hostname}/api/getallcountrylocations?country=${countryCode}`);
};
export const getAllApis = () => {
  if (userToken) {
    return axios.get(`${hostname}/api/getallapis`, { headers });
  } else return Promise.reject('NO Token');
};

export const saveOrder = order => {
  const url = `${hostname}/api/saveorder`;
  return axios.post(url, { order });
};
export const saveNewLocations = locations => {
  return axios.post(`${hostname}/api/savenewlocations`, { locations });
};
export const deleteLocations = locations => {
  return axios.post(`${hostname}/api/deletelocations`, { locations });
};

export const saveChangedRow = row => {
  return axios.put(`${hostname}/api/savechangedlocation`, { location: row });
};

export const saveNewSimCardSale = data => {
  return axios.post(`${hostname}/api/savenewsimcardsale`, { data });
};
export const getAllSimCards = () => {
  return axios.get(`${hostname}/api/getallsimcards`);
};
export const getAllEvents = () => {
  return axios.get(`${hostname}/api/getallevents`, { headers });
};
export const getEventsByType = type => {
  return axios.get(`${hostname}/api/geteventsbytype?type=${type}`, { headers });
};
export const getUpcomingEvents = () => {
  return axios.get(`${hostname}/api/getupcomingevents`, { headers });
};
export const saveNewEvent = event => {
  return axios.post(`${hostname}/api/savenewevent`, { event }, { headers });
};
export const addNewHoliday = holiday => {
  holiday.from = holiday.from.toISOString().split('T')[0] + 'T00:00:00Z';
  holiday.until = holiday.until.toISOString().split('T')[0] + 'T23:59:59Z';
  return axios.post(`${hostname}/api/addnewholiday`, { holiday }, { headers });
};

export const getTopRentACarCars = () => {
  return axios.get(`${hostname}/api/gettoprentacacars`, { headers });
};

export const updateCar = (_id, changes) => {
  return axios.put(`${hostname}/api/updatecar`, { _id, changes }, { headers });
};

export const getOrders = (skip, take) => {
  return axios.get(`${hostname}/api/getorders?skip=${skip}&take=${take}`, {
    headers
  });
};

export const getNumberOfNewOrders = () => {
  return axios.get(`${hostname}/api/getnumberofneworders`);
};

export const getAllLeads = () => {
  return axios.get(`${hostname}/api/getallleads`, { headers });
};
