export const getExtenstions = () => {
  return [
    { columnName: 'daily1to2daysLow', width: 70, align: 'center' },
    { columnName: 'sipp_code', width: 90, align: 'center' },
    { columnName: 'daily1to2daysHigh', width: 75, align: 'center' },
    { columnName: 'daily3to6daysLow', width: 70, align: 'center' },
    { columnName: 'daily3to6daysHigh', width: 75, align: 'center' },
    { columnName: 'SCDW', width: 85, align: 'center' },
    { columnName: 'TP', width: 75, align: 'center' },
    { columnName: 'CDW', width: 75, align: 'center' },
    { columnName: 'weeklyLow', width: 70, align: 'center' },
    { columnName: 'weeklyHigh', width: 75, align: 'center' },
    { columnName: 'monthlyLow', width: 70, align: 'center' },
    { columnName: 'monthlyHigh', width: 75, align: 'center' },
    { columnName: 'LIA', width: 70, align: 'center' }
  ];
};
export const changeInsurances = (row, value, type, isMonthly) => {
  const monthlyDaily = isMonthly ? 'monthly' : 'price';
  return {
    prices: {
      ...row.prices,
      protection_coverage: row.prices.protection_coverage.map(protection =>
        protection.code === type ? { ...protection, [monthlyDaily]: parseInt(value) } : protection
      )
    }
  };
};
