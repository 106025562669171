import React, { useState } from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';

const InputItem = ({ fieldName, handleInputChange }) => (
  <TextField autoComplete='none' fullWidth label={fieldName} onChange={handleInputChange} />
);
const PersonalDetailsForm = ({ formId, issimcard, handleSubmit }) => {
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    age: '',
    phone: '',
    source: '',
    remarks: '',
  });
  const [simplan, setSimPlan] = useState('');

  const handleInputChange = (name, value) => {
    setUserDetails({ ...userDetails, [name]: value });
  };
  const selectValues = ['Phone', 'Adwords', 'Return Customer'];

  return (
    <>
      <Grid item lg={4} sm={4} xl={4} style={{ padding: '8px' }}>
        <form
          id={formId}
          onSubmit={e => handleSubmit(e, { data: userDetails })}
          style={{
            border: '0.5px solid ',
            borderRadius: '4px',
            borderColor: '#C4C4C4',
            padding: '14px',
          }}
        >
          <InputItem
            fieldName='First Name'
            handleInputChange={e => handleInputChange('firstName', e.target.value)}
          />
          <InputItem
            fieldName='Last Name'
            handleInputChange={e => handleInputChange('lastName', e.target.value)}
          />
          <InputItem fieldName='Age' handleInputChange={e => handleInputChange('age', e.target.value)} />
          <InputItem fieldName='Email' handleInputChange={e => handleInputChange('email', e.target.value)} />
          <InputItem fieldName='Phone' handleInputChange={e => handleInputChange('phone', e.target.value)} />
          <FormControl fullWidth style={{ margiTtop: '8px' }}>
            <InputLabel>Source</InputLabel>
            <Select
              fullWidth
              label='Source'
              value={userDetails.source}
              onChange={e => setUserDetails({ ...userDetails, source: e.target.value })}
            >
              {selectValues.map(value => {
                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {issimcard ? (
            <FormControl fullWidth style={{ margiTtop: '8px' }}>
              <InputLabel>Plan</InputLabel>
              <Select fullWidth label='Plan' value={simplan} onChange={e => setSimPlan(e.target.value)}>
                <MenuItem value='tourist'>Tourist</MenuItem>
                <MenuItem value='business'>Businessman</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <></>
          )}{' '}
        </form>
      </Grid>

      {/**Remarks field */}
      <Grid item lg={8} sm={8} style={{ padding: '8px' }}>
        <Grid container direction='column' justify='space-between' spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              variant='outlined'
              label='Remarks'
              multiline
              rows={16}
              rowsMax={20}
              value={userDetails.remarks}
              onChange={e => handleInputChange('remarks', e.target.value)}
            />
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button form={formId} type='submit' variant='contained'>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default PersonalDetailsForm;
