import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from 'sharedState/authState';

const RouteWithLayout = props => {
  const [authState] = React.useContext(AuthContext);
  const { layout: Layout, component: Component, isPublic, ...rest } = props;
  const ComponentWithLayout = matchProps => {
    return (
      <Layout>
        <Component {...matchProps} />
      </Layout>
    );
  };

  return (
    <Route
      {...rest}
      render={matchProps =>
        isPublic || authState.isAuthenticated ? (
          <ComponentWithLayout matchProps {...rest} />
        ) : (
          <Redirect to='/sign-in' />
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  isPublic: PropTypes.bool
};

export default RouteWithLayout;
