import React, { useState, useRef, useCallback } from 'react';
import { Paper } from '@material-ui/core';
import { onSave } from '../../../helpers';
import {
  SortingState,
  IntegratedSorting,
  SearchState,
  DataTypeProvider,
  PagingState,
  IntegratedPaging
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { toggleButton } from '../../../assets/reusableItems/items';
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  PagingPanel,
  ExportPanel,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';

const toolbarRoot = props => <Toolbar.Root {...props} style={{ flexDirection: 'row-reverse' }} />;

const QuotesTable = ({ quotes, handleQuoteDescriptionOpen }) => {
  const [pageSizes] = useState([10, 15, 20, 0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [rows] = useState(quotes);
  console.log(quotes);
  const exporterRef = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
    console.log('here', exporterRef.current);
  }, [exporterRef]);


  const columns = [
    {
      name: 'quoteDate',
      title: 'Date',
      getCellValue: row => new Date(row.quotation.quoteDate)
    },
    { name: 'fullName', title: 'Full name' },
    { name: 'email', title: 'Email' },
    { name: 'phone', title: 'Phone' }
  ];
  const [tableColumnExtensions] = useState([
    { columnName: 'quoteDate', width: '20%' },
    { columnName: 'fullName', width: '20%' },
    { columnName: 'email', width: '25%' }
    // { columnName: 'source', width: '30%' },
    // { columnName: 'comment', width: '30%' },
  ]);

  const DateFormatter = ({ value }) => new Date(value).toLocaleString('en-GB');
  const [dateColumns] = useState(['quoteDate']);
  const DateTypeProvider = props => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      // eslint-disable-next-line no-alert
      onClick={() => handleQuoteDescriptionOpen(row)}
      style={{
        cursor: 'pointer'
        // ...styles[row.sector.toLowerCase()],
      }}
    />
  );

  return (
    <Paper>
      {rows.length > 0 ? (
        <>
          {' '}
          <Grid rows={rows} columns={columns}>
            <VirtualTable />
            <DateTypeProvider for={dateColumns} />
            <SortingState defaultSorting={[{ columnName: 'quoteDate', direction: 'desc' }]} />
            <IntegratedSorting />

            {/**TOOLBAR HERE */}
            <Toolbar rootComponent={toolbarRoot} />

            <ExportPanel startExport={startExport} toggleButtonComponent={toggleButton} />

            {/**SEARCH */}
            <SearchState />
            <SearchPanel />
            {/**SEARCH END */}

            {/**PAGING */}
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            {/**END PAGING */}

            <Table rowComponent={TableRow} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />

            <PagingPanel pageSizes={pageSizes} />
            {/* < TableColumnVisibility
                    defaultHiddenColumnNames={'lastCommentDate'}
                /> */}
          </Grid>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            columns={columns}
            onSave={workbook => onSave(workbook, 'quotes')}
          />{' '}
        </>
      ) : null}
    </Paper>
  );
};

export default QuotesTable;
