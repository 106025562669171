import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import suppliers from '../../../../../data/suppliers';

const ReservationDetails = ({ order, setOrder }) => {
  let sortedSuppliers = suppliers.sort((s1, s2) => {
    if (s1.code < s2.code) return -1;
    if (s1.code > s2.code) return 1;
    return 0;
  });
  // console.log('ORDER', order);
  const handleSupplierReservationDataChange = (name, value) => {
    setOrder({
      ...order,
      supplierReservationData: {
        ...order.supplierReservationData,
        [name]: value,
      },
    });
  };
  const {
    contractNumber,
    accountNumber,
    reservationNumber,
  } = order.supplierReservationData;
  return (
    <>
      <Typography style={{ textAlign: 'center' }} variant="h6">
        Reservation details
      </Typography>
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Supplier confirmation"
            value={reservationNumber}
            onChange={({ target: { value } }) =>
              setOrder({
                ...order,
                supplierReservationData: {
                  ...order.supplierReservationData,
                  reservationNumber: value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Supplier</InputLabel>

            <Select
              fullWidth
              label="Supplier"
              value={order.supplier.code}
              onChange={(e) =>
                setOrder({
                  ...order,
                  supplier: suppliers.find(
                    (supplier) => supplier.code === e.target.value
                  ),
                })
              }
            >
              {sortedSuppliers.map((supplier, idx) => (
                <MenuItem value={supplier.code} key={idx}>
                  {supplier.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Account no."
            name="accountNumber"
            value={accountNumber}
            onChange={(e) =>
              handleSupplierReservationDataChange(e.target.name, e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Contract no."
            name="contractNumber"
            value={contractNumber}
            onChange={(e) =>
              handleSupplierReservationDataChange(e.target.name, e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            label="Order Number"
            name="orderId"
            value={order.orderId}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ReservationDetails;
