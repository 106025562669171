import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { createNewPriceList } from 'apiRequests';
import { useFormik } from 'formik';

const NewPriceListDialog = ({ open, setOpen, setChanges, setIsSaved }) => {
  const [disabled, setDisabled] = useState(false);
  const formik = useFormik({
    initialValues: {
      supplier: 'Europcar',
      priceListName: '',
      isActive: false,
      isReligious: false,
      currency: 'USD',
      isPromo: false
    },
    onSubmit: ({ priceListName, isActive, isReligious, currency, supplier, isPromo }) => {
      setDisabled(true);
      createNewPriceList(priceListName, isActive, isReligious, currency, supplier, isPromo)
        .then(() => {
          setOpen(false);
          setChanges(prevState => ({ changes: !prevState.changes }));
          setIsSaved({ status: true, type: 'success', message: `Price List ${priceListName} created` });
        })
        .catch(err => {
          console.log('ERROR', err);
          console.log(err.response.data.message);
          alert(err.response.data.message);
        })
        .finally(
          setTimeout(() => {
            setDisabled(false);
          }, 1000)
        );
    },
    validate: values => {
      let errors = {};
      if (!values.priceListName) {
        errors.priceListName = 'Required field';
      }
      return errors;
    }
  });
  //   useEffect(() => {
  //     console.log('VALUES', formik.values);
  //   }, [formik]);
  return (
    <Dialog onClose={() => setOpen(false)} open={open} maxWidth='lg'>
      <form
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <DialogTitle onClose={() => setOpen(false)}>
          <Typography variant='h5' component='span'>
            New rate code
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Container style={{ border: '1px solid', padding: 32, borderRadius: 4, borderColor: '#0000001f' }}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <TextField
                  required
                  id='priceListName'
                  label='Rate code name'
                  variant='outlined'
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel>Currency</InputLabel>

                  <Select
                    id='currency'
                    name='currency'
                    label='Currency'
                    value={formik.values.currency}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value='USD'>USD</MenuItem>
                    <MenuItem value='ILS'>ILS</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel>Supplier</InputLabel>

                  <Select
                    id='supplier'
                    name='supplier'
                    label='Supplier'
                    value={formik.values.supplier}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value='Europcar'>Europcar</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='isReligious'
                      checked={formik.values.isReligious}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Religious'
                />
              </Grid>
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Checkbox id='isActive' checked={formik.values.isActive} onChange={formik.handleChange} />
                  }
                  label='Active'
                />
              </Grid>
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Checkbox id='isPromo' checked={formik.values.isPromo} onChange={formik.handleChange} />
                  }
                  label='Promo'
                />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDisabled(false);
              setOpen(false);
            }}
            color='primary'
          >
            Cancel
          </Button>

          <div style={{ position: 'relative' }}>
            <Button color='primary' type='submit' disabled={disabled} style={{ margin: '16px 0px' }}>
              Create
            </Button>
            {disabled && (
              <CircularProgress
                size={24}
                style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }}
              />
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewPriceListDialog;
