import React, { forwardRef } from 'react';
import { CircularProgress, Grid, Button, Slide } from '@material-ui/core';
import styled from 'styled-components';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

export const ViewProgressBar = () => (
  <Grid style={{ margin: 'auto' }}>
    <CircularProgress style={{ height: '50px', width: '50px' }} />
  </Grid>
);

export const ViewContainer = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(4)}px;
  height: 90vh;
  /* overflow: hidden; */
`;
export const AddAndSaveButton = ({ addOrSave, onClickFunction, disabledStatus, buttonName }) => (
  <Button
    onClick={() => onClickFunction(addOrSave)}
    variant='contained'
    color='default'
    size='small'
    disabled={disabledStatus}
    startIcon={addOrSave === 'save' ? <SaveIcon /> : <AddIcon />}
  >
    {buttonName}
  </Button>
);

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const toggleButton = props => {
  return (
    <Button buttonRef={props.buttonRef} onClick={props.onToggle}>
      <FontAwesomeIcon icon={faFileExcel} style={{ fontSize: '25px', color: 'green' }} />
    </Button>
  );
};
