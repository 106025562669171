import { getCarsPrices } from 'apiRequests';
import React, { useState, createContext, useEffect } from 'react';

export const AuthContext = createContext();
export const LeadsContext = createContext();
export const CarsContext = createContext();
export const ChangesContext = createContext();
// export const NewOrdersContext = createContext();
export const OrdersContext = createContext();
export const CustomersContext = createContext();

const Store = ({ children }) => {
  const tokens = JSON.parse(localStorage.getItem('jwt-token'));
  const user = JSON.parse(localStorage.getItem('user'));

  const [leads, setLeads] = useState([]);
  const [cars, setCars] = useState([]);
  const [changes, setChanges] = useState(false);
  const [numberOfNewOrders, setNumberOfNewOrders] = useState(0);
  const [orders, setOrders] = useState([]);
  const [authState, setAuthState] = useState({
    isAuthenticated: Boolean(tokens),
    user,
  });
  // useEffect(() => {
  //   console.log('auth state is', authState);
  // }, [authState]);

  useEffect(() => {
    const handleNumberNewOrders = (numberOfNewOrders) => {
      setNumberOfNewOrders(numberOfNewOrders + 1);
    };
    if (authState.isAuthenticated) {
      // console.log('the token is', authState.token);
      const { token } = authState;
      Promise.all([getCarsPrices()])
        .then((result) => {
          // setCustomers(result.data.customers.filter(customer=>!customer.lead.isLead))
          // setLeads(makeRowsFromLeads(leadCustomers));
          setCars(result[0].data);
        })
        .catch((err) => console.error(err));
    }
  }, [authState, changes]);

  return (
    <AuthContext.Provider value={[authState, setAuthState]}>
      <OrdersContext.Provider value={[orders, setOrders]}>
        <LeadsContext.Provider value={[leads, setLeads]}>
          <ChangesContext.Provider value={[changes, setChanges]}>
            <CarsContext.Provider value={[cars, setCars]}>
              {children}
            </CarsContext.Provider>{' '}
          </ChangesContext.Provider>
        </LeadsContext.Provider>
      </OrdersContext.Provider>
    </AuthContext.Provider>
  );
};

export default Store;
