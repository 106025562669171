import { Button, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';

const ApiCard = ({ setOpen, api }) => {
  return (
    <Card style={{ marginBottom: 8 }}>
      <CardContent>
        <Typography variant='h6'>{api.apiName}</Typography>
        <Typography variant='body1'>
          Commission fee | {api.commissionFeePercent}%{' '}
          <Button variant='text' onClick={() => setOpen({ status: true, api })}>
            Change
          </Button>
          <br />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ApiCard;
