import React, { useState, useEffect, useReducer } from 'react';
import { Paper, Grid, Badge, Typography } from '@material-ui/core';
import OrdersTable from './Components/OrdersTable';
import AddNewOrder from './Components/AddNewOrder/AddNewOrder';
import { OrdersContext } from 'sharedState/authState';
import {
  ViewProgressBar,
  ViewContainer,
  AddAndSaveButton,
} from '../../assets/reusableItems/items';
const handleClick = () => {
  console.log('clicked');
};
const today = new Date();

const NewsRow = ({ title, count }) => {
  return (
    <Grid item>
      <Paper
        elevation={3}
        style={{
          padding: '8px',
          width: '200px',
          height: '50px',
          textAlign: 'center',
          display: 'table-cell',
          verticalAlign: 'middle',
        }}
      >
        <Badge onClick={handleClick} badgeContent={count} color="secondary">
          <Typography variant="subtitle1">{title}</Typography>
        </Badge>
      </Paper>
    </Grid>
  );
};

const Orders = () => {
  const [orders] = React.useContext(OrdersContext);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [changes, setChanges] = useState(false);
  const [notifications, setNotifications] = useState({
    todayOrders: 0,
    waitingConfirmation: 0,
  });

  const VIRTUAL_PAGE_SIZE = 100;
  const MAX_ROWS = 50000;

  const initialState = {
    rows: [],
    skip: 0,
    requestedSkip: 0,
    take: VIRTUAL_PAGE_SIZE * 2,
    totalCount: 0,
    loading: false,
    lastQuery: '',
  };
  const start =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://crm.we4rent.com';
  const url = `${start}/api/orders/allorders`;

  const [state, dispatch] = useReducer(reducer, initialState);

  const buildQueryString = (skip, take) => `${URL}?skip=${skip}&take=${take}`;
  function reducer(state, { type, payload }) {
    switch (type) {
      case 'UPDATE_ROWS':
        return {
          ...state,
          ...payload,
          loading: false,
        };
      case 'START_LOADING':
        return {
          ...state,
          requestedSkip: payload.requestedSkip,
          take: payload.take,
        };
      case 'REQUEST_ERROR':
        return {
          ...state,
          loading: false,
        };
      case 'FETCH_INIT':
        return {
          ...state,
          loading: true,
        };
      case 'UPDATE_QUERY':
        return {
          ...state,
          lastQuery: payload,
        };
      default:
        return state;
    }
  }

  useEffect(() => {
    let result = [];
    orders.forEach((order) => {
      if (!order.isQuotation) {
        const orderDate = new Date(order.orderDate);
        if (
          orderDate.getDate() === today.getDate() &&
          orderDate.getMonth() === today.getMonth() &&
          orderDate.getFullYear() === today.getFullYear()
        ) {
          setNotifications((prevState) => {
            return { ...prevState, todayOrders: prevState.todayOrders + 1 };
          });
        }
        if (order.status === 'On request') {
          setNotifications((prevState) => {
            return {
              ...prevState,
              waitingConfirmation: prevState.waitingConfirmation + 1,
            };
          });
        }
        result.push(order);
      }
    });

    setRows(result);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  // useEffect(() => {
  //   console.log('CHANGES ARE', changes);
  // }, [changes]);
  // useEffect(() => {
  //   console.log('notifications', notifications);
  // }, [notifications]);

  // setInterval(function () {
  //     check();
  // }, 10000);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ViewContainer container>
      {isLoading ? (
        <ViewProgressBar />
      ) : (
        <>
          <Grid container spacing={4}>
            <NewsRow title={'New Orders'} count={notifications.todayOrders} />
            <NewsRow
              title={'Waiting confirmation'}
              count={notifications.waitingConfirmation}
            />
            {/* <NewsRow title={'New Orders'} />
                        <NewsRow title={'New Orders'} /> */}
          </Grid>
          <Grid
            container
            style={{ padding: '8px 0px', marginTop: '32px' }}
            justify="space-between"
          >
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <AddAndSaveButton
                    addOrSave="addOrder"
                    onClickFunction={handleClickOpen}
                    buttonName="Add new order"
                  />
                </Grid>
                <Grid item>
                  <AddAndSaveButton
                    addOrSave="save"
                    disabledStatus={true}
                    buttonName="save"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {open && <AddNewOrder open={open} setOpen={setOpen} editOrder={{}} />}
          <OrdersTable
            setChanges={setChanges}
            orders={rows}
            changes={changes}
          />
        </>
      )}
    </ViewContainer>
  );
};

export default Orders;
